import React, { useEffect, useState } from "react";
import "../Authentication.css";
import { useHistory } from "react-router-dom";
import verify from "../../../../assets/img/Forget.svg";
import tic from "../../../../assets/img/lock.svg";

import { RiErrorWarningLine } from "react-icons/ri";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Key } from "phosphor-react";
import { showToast } from "../../../../utils/ToastHelper";
import { BASE_URL } from "../../../Const/Url";
import axios from "axios";

export default function ResetPassword({ setHideToolbar }) {
  const [passValidation, setPassValidation] = useState("");
  const [activeOtp, setActiveOtp] = useState(0);
  const [finalOtp, setFinalOtp] = useState(null);
  const history = useHistory();
  const [eye, setEye] = useState(false);
  const [eye1, setEye1] = useState(false);
  const [finalPass, setFinalPass] = useState("");
  useEffect(() => {
    setHideToolbar(true);
    return () => {
      setHideToolbar(false);
    };
  }, []);
  const changeEye = () => {
    setEye((prev) => !prev);
  };
  // For Modals
  const getBackDashboard = () => {
    setHideToolbar(false);
    history.push("/dashboard");
  };
  const mobile = localStorage.getItem("userNumberOtp");
  const SignInData = (pass) => {
    const data = new FormData();
    data.append("mobile", mobile);
    data.append("password", pass);
    const url = `${BASE_URL}api/v1/auth/login/`;

    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Logged In Successfully.");
          // Store data's to local storage
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("access_token", res.data.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          if (localStorage.getItem("is_logged_in")) {
            setHideToolbar(false);
            history.push("/");
          }
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }

        showToast("error", message);
      });
  };
  const submitOtp = () => {
    // const otpC = `${otp[0]}${otp[1]}${otp[2]}${otp[3]}${otp[4]}${otp[5]}`
    // localStorage.setItem('forgetOtp',otpC)
    if (finalOtp?.length !== 6) {
      showToast("error", "Otp Should Be 6 Digit");
    } else if (finalPass.length < 8) {
      showToast("error", "Enter minimum 8 digit password!");
    } else {
      const data = {
        // code: `${otp[0]}${otp[1]}${otp[2]}${otp[3]}${otp[4]}${otp[5]}`,
        code: finalOtp,
        mobile: mobile,
        password: finalPass,
      };
      const url = `${BASE_URL}api/v1/auth/forget/password/confirm/`;
      console.log(data, "final data");
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.status) {
            SignInData(finalPass);
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
            console.log(value[0], "otp error");
          }

          showToast("error", message);
        });
    }
  };

  return (
    <div className="login_main">
      <div>
        <div className="logo_image" style={{ position: "relative" }}>
          <img className="img-fluid" src={verify} alt="" />
          <img className="img-fluid tic" src={tic} alt="" />
        </div>
        <h3 className="login_text">Create New Password </h3>
        <div className="verify-main">
          <span className="verify-text">
            Your new password must be different from <br />
            previously used password
          </span>
        </div>

        <div className="input_container create mt-3">
          <div className="number">
            {/* <div className="icons-div">
                <Key size={24} color="white" weight="light" />
              </div> */}
            <input
              type="number"
              // onChange={(e) => setNumberValidation(e.target.value)}
              placeholder="Type OTP"
              name=""
              id=""
              autocomplete="off"
              value={finalOtp}
              onChange={(e) => setFinalOtp(e.target.value)}
            />
          </div>
          {finalOtp?.length < 6 || finalOtp?.length > 6 ? (
            <span>
              <RiErrorWarningLine /> Must Be 6 characters
            </span>
          ) : finalOtp?.length === 0 ? (
            <></>
          ) : (
            <></>
          )}
          {/* {isNaN(Number(numberValidation)) == true &&
              numberValidation.length > 0 && (
                <span className="mt-1">
                  <RiErrorWarningLine /> Inter Number Only
                </span>
              )} */}
        </div>

        <div className="input_container mt-3">
          <span>New Password</span>
          <div className="number mt-1" style={{ position: "relative" }}>
            <input
              type={eye ? "text" : "password"}
              className="w-100"
              autoComplete="false"
              onChange={(e) => setPassValidation(e.target.value)}
            />
            <div onClick={() => changeEye()}>
              {eye ? (
                <AiOutlineEye
                  style={{ cursor: "pointer" }}
                  size={24}
                  color="#0000001A"
                />
              ) : (
                <AiOutlineEyeInvisible
                  style={{ cursor: "pointer" }}
                  size={24}
                  color="#0000001A"
                />
              )}
            </div>
          </div>
          {passValidation?.length > 0 && passValidation?.length < 8 && (
            <span>
              <RiErrorWarningLine /> Minimum 8 characters
            </span>
          )}
        </div>
        <div className="input_container mt-3">
          <span>Confirm Password</span>
          <div className="number mt-1" style={{ position: "relative" }}>
            <input
              type={eye ? "text" : "password"}
              className="w-100"
              autoComplete="false"
              onChange={(e) => setFinalPass(e.target.value)}
            />
            <div onClick={() => changeEye()}>
              {eye ? (
                <AiOutlineEye
                  style={{ cursor: "pointer" }}
                  size={24}
                  color="#0000001A"
                />
              ) : (
                <AiOutlineEyeInvisible
                  style={{ cursor: "pointer" }}
                  size={24}
                  color="#0000001A"
                />
              )}
            </div>
          </div>
          {finalPass?.length > 0 && finalPass !== passValidation && (
            <span>
              <RiErrorWarningLine /> Did Not Match
            </span>
          )}
        </div>
        <div className="input_container" style={{ marginTop: "15px" }}>
          <button
            onClick={submitOtp}
            className="w-100  btn-outline-none"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
