import { Checkbox, Menu, MenuItem } from "@material-ui/core";
import { Lock, MagnifyingGlass, Power, UserCircle, X } from "phosphor-react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { logout_func3 } from "../../Const/logoutCommon";
import add from "../../../assets/img/add.svg";
import { RiErrorWarningLine } from "react-icons/ri";
import { useState } from "react";
import select from "../../../assets/img/select.svg";
import { BASE_URL } from "../../Const/Url";
import { showToast } from "../../Const/ToastHelper";
import axios from "axios";
import PmLoader from "../../CommonDesgin/PmLoader";

const EditAdminStaff = () => {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  const CommonData = {
    title: "Edit Admin and Staff",
    path: "Dashboard/ Administration/ Admin and Staff/ Edit Admin and Staff ",
    add: true,
    btnData: "Add New Admin",
    search: true,
  };
  const { id } = useParams();
  const history = useHistory();
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [eye, setEye] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [staff, setStaff] = useState(false);
  const [superadmin, setSuperadmin] = useState(false);
  const [image, setImage] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [singleStaff,setSingleStaff] = useState({})
  const onSelectFile = (event) => {
    setUploaded(event.target.files[0]);
  };
  const removeImg = () => {
    setUploaded(null);
  };
  const getSingleStaff = () => {
    const url2 = `${BASE_URL}api/v1/auth/staff/${id}`;
    axios
      .get(url2)
      .then((res) => {
        console.log(res.data.data);
        setFirstName(res.data.data.first_name);
        setLastName(res.data.data.last_name);
        setEmail(res.data.data.email);
        setMobile(res.data.data.mobile);
        setIsActive(res.data.data.is_active);
        setStaff(res.data.data.is_staff);
        setSuperadmin(res.data.data.is_superuser);
        setPreview(res.data.data.image_url);
        setSingleStaff(res.data.data)
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }

        // showToast("error", message);
      });
  };
  useEffect(() => {
    getSingleStaff();
  }, []);
  if (loading) {
    return <PmLoader />;
  }
  console.log(
    {
      staff: staff,
      admin: superadmin,
      active: isActive,
    },
    "checkbox"
  );
  const updateData = () => {
    const url2 = `${BASE_URL}api/v1/auth/staff/${id}/`;
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    if (uploaded !== null) {
      const data1 = new FormData();
      data1.append("document", uploaded);
      data1.append("doc_type", 0);
      axios
        .post(url1, data1)
        .then((res) => {
          console.log(res.data.data);
          if (res.data.status) {
            axios
              .patch(url2, {
                first_name: firstName,
                last_name: lastName,
                email: email,
                image: res.data.data.id,
                is_active: isActive,
                is_staff: staff,
                is_superuser: superadmin,
              })
              .then((res) => {
                if (res.data.status) {
                  console.log(res.data);
                  showToast("success", "Data Updated Successfully.");
                  setUploaded(null);
                  history.push("/admin-and-staff")
                }
              })
              .catch((err) => {
                const message = JSON.parse(err.request.response).message;
                const errorMsg = JSON.parse(err.request.response).errors;
                for (let value of Object.values(errorMsg)) {
                  showToast("error", value[0]);
                }

                showToast("error", message);
              });
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }
          console.log(err.request.response);
          showToast("error", message);
        });
    } else {
      const userData = {first_name: firstName,
        last_name: lastName,
        email: email,
        image: singleStaff?.image,
        is_active: isActive,
        is_staff: staff,
        is_superuser: superadmin,};
      axios
        .patch(url2, userData)
        .then((res) => {
          if (res.data.status) {
            showToast("success", " Data Updated Successfully. !");
            history.push("/admin-and-staff")
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }

          showToast("error", message);
        });
    }
  };
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <div>
        <div className="upper">
          <div>
            <h5>{CommonData?.title}</h5>
            <p>{CommonData?.path}</p>
          </div>
          <div
            className="upper_user"
            aria-controls={open2 ? "long-menu" : undefined}
            aria-expanded={open2 ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick2}
          >
            <img src={userProfile?.image_url} alt="" />
          </div>
        </div>
        <>
          <Menu
            id="long-menu"
            className="pop_over3"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            PaperProps={{
              style: {
                // maxHeight: ITEM_HEIGHT * 4.5,
                width: "22ch",
                marginRight: "10ch",
                top: "89px !important",
                padding: "5px ",
              },
            }}
          >
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                history.push("/view-profile");
                handleClose2();
              }}
            >
              {" "}
              <UserCircle
                color="#1F5DA0"
                size={24}
                weight="bold"
                className="me-3"
              />
              View Profile
            </MenuItem>
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                history.push("/change-password");
                handleClose2();
              }}
            >
              {" "}
              <Lock color="#1F5DA0" size={24} weight="bold" className="me-3" />
              Change Password
            </MenuItem>
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                // history.push('/login')
                logout_func3(history);
                handleClose2();
              }}
            >
              {" "}
              <Power color="#1F5DA0" size={24} weight="bold" className="me-3" />
              Logout
            </MenuItem>
          </Menu>
        </>
      </div>
      <div className="content_div bg-white mt-3 row gap-2">
        <div className="col-12 col-md-4 picture">
          <p>Profile Picture</p>
          <div className="select_img">
            <img
              style={{ width: "80%", height: "90%", objectFit: "cover" }}
              src={uploaded ? URL.createObjectURL(uploaded) : preview}
              alt=""
            />
            {uploaded && (
              <div className="cross" onClick={removeImg}>
                <X size={20} weight="thin" />
              </div>
            )}
          </div>
          <div
            className="d-flex add-user-btn mt-4 df gap-1"
            style={{ width: "140px" }}
          >
            <img className="icon" src={add} alt="" /> Upload
            <input
              onChange={onSelectFile}
              type="file"
              accept="image/*"
              name=""
              id=""
            />
          </div>
        </div>
        <div className="col-12 col-md-7 mb-4 pb-4">
          <div className="row">
            <div className="col-12 col-md-6 global">
              <p>First Name</p>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                placeholder="First Name"
              />
            </div>
            <div className="col-12 col-md-6 global">
              <p>Last Name</p>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="Last Name"
              />
            </div>

            <div className="col-12 row global mt-3 input_container">
              <p className="col-12">Phone Number</p>
              <div
                className="number col-12"
                style={{ width: "100%", marginLeft: "10px" }}
              >
                {/* <select
                  onChange={(e) => setUserCountryCode(e.target.value)}
                  name=""
                  id=""
                >
                  {countryCode?.map((country) => (
                    <option
                      selected={country.phone_code === "+880"}
                      key={country.id}
                      value={country.phone_code}
                    >
                      {country.phone_code}
                    </option>
                  ))}
                </select> */}

                <input
                  type="text"
                  // onChange={(e) => setNumberValidation(e.target.value)}
                  value={mobile}
                  className="w-100"
                  style={{ border: "none", outline: "none" }}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 global mt-3 input_container">
              <p>Email</p>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter a valid email"
              />
              {!email?.match(mailformat) && email?.length > 0 && (
                <span>
                  <RiErrorWarningLine /> invalid Email
                </span>
              )}
            </div>

            {/* <div className="input_container mt-3">
              <span>Password</span>
              <div className="number" style={{ position: "relative" }}>
                <input
                  type={eye ? "text" : "password"}
                  className="w-100"
                  autoComplete="false"
                  onChange={(e) => setPassValidation(e.target.value)}
                />
                <div onClick={() => changeEye()}>
                  {eye ? (
                    <AiOutlineEye
                      style={{ cursor: "pointer" }}
                      size={24}
                      color="#0000001A"
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ cursor: "pointer" }}
                      size={24}
                      color="#0000001A"
                    />
                  )}
                </div>
              </div>
              {passValidation?.length > 0 && passValidation?.length < 8 && (
                <span>
                  <RiErrorWarningLine /> Minimum 8 characters
                </span>
              )}
            </div> */}
            <div className="col-12 global mt-3 d-flex align-items-center">
              <div
                className="d-flex align-items-center"
                style={{ minWidth: "120px" }}
              >
                <p>Is Active</p>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-10px" }}
              >
                <Checkbox
                  checked={isActive}
                  // defaultValue={isActive}
                  onChange={() => setIsActive(!isActive)}
                  label="Outlined"
                  variant="outlined"
                />{" "}
                Yes
              </div>
            </div>
            <div className="col-12 global mt-1 d-flex align-items-center">
              <p style={{ minWidth: "120px" }}>Is Staff</p>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-10px" }}
              >
                <Checkbox
                  checked={staff === true}
                  onChange={() => setStaff((prev) => !prev)}
                  label="Outlined"
                  variant="outlined"
                />{" "}
                Yes
              </div>
            </div>
            <div className="col-12 global mt-1 d-flex align-items-center">
              <p style={{ minWidth: "120px" }}>Is Super Admin</p>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-10px" }}
              >
                <Checkbox
                  checked={superadmin === true}
                  onChange={() => setSuperadmin((prev) => !prev)}
                  label="Outlined"
                  variant="outlined"
                />{" "}
                Yes
              </div>
            </div>
            <div className="col-12 global2 mt-3">
              <button
                className="back"
                onClick={() => history.push("/admin-and-staff")}
              >
                Back
              </button>
              <button className="ms-2  save" onClick={updateData}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdminStaff;
