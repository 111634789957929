import React from "react";

const Messege = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_2_75)">
        <path
          d="M20 4H4V16H18.83L20 17.17V4ZM20 2C21.1 2 21.99 2.9 21.99 4L22 22L18 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20ZM18 12H6V14H18V12ZM18 9H6V11H18V9ZM18 6H6V8H18V6Z"
          fill="#1F5DA0"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_75">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Messege;
