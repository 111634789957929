import { Files, ImageSquare, Lock, MagnifyingGlass, Power, UserCircle } from "phosphor-react";
import React from "react";
import userDemo from "../../assets/img/userdemo.svg";
import "./Common.css";
import add from "../../assets/img/add.svg";
import { Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { logout_func3 } from "../Const/logoutCommon";

const PageHeader = ({ CommonData }) => {
  const history = useHistory()
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const userProfile = JSON.parse(localStorage.getItem('userProfile'))
  return (
    <div>
      <div className="upper">
        <div>
          <h5>{CommonData?.title}</h5>
          <p>{CommonData?.path}</p>
        </div>
        <div
          className="upper_user"
          aria-controls={open2 ? "long-menu" : undefined}
          aria-expanded={open2 ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick2}
        >
          <img src={userProfile ? userProfile?.image_url : userDemo} alt="" />
        </div>
      </div>
      <>
        <Menu
          id="long-menu"
          className="pop_over3"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              width: "22ch",
              marginRight: "10ch",
              top: "89px !important",
              padding: "5px ",
            },
          }}
        >
          <MenuItem className="privacy my-2" onClick={()=>{
            history.push('/view-profile')
            handleClose2()
          }}>
            {" "}
            <UserCircle
              color="#1F5DA0"
              size={24}
              weight="bold"
              className="me-3"
            />
            View Profile
          </MenuItem>
          <MenuItem className="privacy my-2" onClick={()=>{
            history.push('/change-password')
            handleClose2()
          }}>
            {" "}
            <Lock color="#1F5DA0"
              size={24} weight="bold" className="me-3" />
            Change Password
          </MenuItem>
          <MenuItem className="privacy my-2" onClick={()=>{
            logout_func3(history)
            handleClose2()
          }}>
            {" "}
            <Power color="#1F5DA0"
              size={24} weight="bold" className="me-3" />
            Logout
          </MenuItem>
        </Menu>
      </>
      <div className="lower mt-4">
        {CommonData.search === true && (
          <div className="input">
            <input type="text" placeholder="Search ..." />
            <button>
              <MagnifyingGlass color="white" size={28} weight="bold" />
            </button>
          </div>
        )}

        {CommonData.add === true && (
          <div>
            <button className="d-flex add-user-btn">
              <img className="icon" src={add} alt="" />
              <span className="ms-2">{CommonData.btnData}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
