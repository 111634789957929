import React, { useEffect, useState } from "react";
import PageHeader from "../../CommonDesgin/PageHeader";
import "../Administration.css";
import select from "../../../assets/img/select.svg";
import add from "../../../assets/img/add.svg";
import { X } from "phosphor-react";
import { logout_func3 } from "../../Const/logoutCommon";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../Const/Url";
import PmLoader from "../../CommonDesgin/PmLoader";
import { showToast } from "../../Const/ToastHelper";

const GlobalSettings = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [globalData, setGlobalData] = useState({});
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  const CommonData = {
    title: "Global Settings",
    path: "Dashboard/ Administration/ Global Settings",
    add: false,
    btnData: "",
    search: false,
  };
  const [image, setImage] = useState(globalData?.logo_url);
  const [uploaded, setUploaded] = useState(null);
  const [preview, setPreview] = useState(null);
  const [email, setEmail] = useState(globalData?.email);
  const [address, setAddress] = useState(globalData?.address);
  const [mobile, setMobile] = useState("");
  const [siteName, setSiteName] = useState(globalData?.site_name);
  const [siteUrl, setSiteUrl] = useState(globalData?.website_url);
  const [desc, setDesc] = useState(globalData?.short_desc);
  const onSelectFile = (event) => {
    setUploaded(event.target.files[0]);
    // FOR BUG IN CHROME
    // event.target.value = "";
  };
  // console.log(preview, "preview");
  const removeImg = () => {
    setUploaded(null);
    setPreview(null);
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data, "res");
        setEmail(res?.data?.data?.email);
        setImage(res.data.data?.logo_url);
        setDesc(res.data.data?.short_desc);
        setSiteUrl(res.data.data?.website_url);
        setSiteName(res.data.data?.site_name);
        setAddress(res.data.data?.address);
        setMobile(res.data.data?.phone);
        setGlobalData(res.data.data);
        console.log("phone", res.data.data?.phone.split("+")[1]);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
      });
  };

  useEffect(() => {
    // apiFunction(`${BASE_URL}v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}`)
    apiFunction(`${BASE_URL}api/v1/utility/admin/global-settings/`);
  }, []);

  const postGlobal = () => {
    const url2 = `${BASE_URL}api/v1/utility/admin/global-settings/`;
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    if (uploaded !== null) {
      const data1 = new FormData();
      data1.append("document", uploaded);
      data1.append("doc_type", 0);
      axios
        .post(url1, data1)
        .then((res) => {
          console.log(res.data.data);
          if (res.data.status) {
            axios
              .post(url2, {
                site_name: siteName,
                website_url: siteUrl,
                email: email,
                phone: mobile,
                address: address,
                short_desc: desc,
                facebook: "string",
                twitter: "string",
                linkedin: "string",
                instagram: "string",
                youtube: "string",
                shipping_fee: "-10912",
                vat_percentage: "256442.5",
                sms_apikey: "527520d64dd4a648",
                sms_secret: "907191e6",
                logo: res.data.data.id,
              })
              .then((res) => {
                if (res.data.status) {
                  console.log(res.data);
                  showToast("success", "Data Updated Successfully.");
                  apiFunction(url2)
                  setUploaded(null);
                }
              })
              .catch((err) => {
                const message = JSON.parse(err.request.response).message;
                const errorMsg = JSON.parse(err.request.response).errors;
                for (let value of Object.values(errorMsg)) {
                  showToast("error", value[0]);
                }

                showToast("error", message);
              });
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }
          console.log(err.request.response);
          showToast("error", message);
        });
    } else {
      const userData = {
        site_name: siteName,
        website_url: siteUrl,
        email: email,
        phone: mobile,
        address: address,
        short_desc: desc,
        facebook: "string",
        twitter: "string",
        linkedin: "string",
        instagram: "string",
        youtube: "string",
        shipping_fee: "-10912",
        vat_percentage: "256442.5",
        sms_apikey: "527520d64dd4a648",
        sms_secret: "907191e6",
        logo: globalData?.logo,
      };
      axios
        .post(url2, userData)
        .then((res) => {
          if (res.data.status) {
            apiFunction(url2);

            showToast("success", " Data Updated Successfully. !");
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }

          showToast("error", message);
        });
    }
  };
  if (loading) {
    return <PmLoader />;
  }
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <PageHeader CommonData={CommonData} />
      <div className="content_div bg-white row gap-2">
        <div className="col-12 col-md-4 picture">
          <p>Profile Picture</p>
          <div className="select_img">
            <img
              style={{ width: "80%", height: "90%", objectFit: "cover" }}
              src={uploaded ? URL.createObjectURL(uploaded) : image}
              alt=""
            />
            {uploaded && (
              <div className="cross" onClick={removeImg}>
                <X size={20} weight="thin" />
              </div>
            )}
          </div>
          <button
            className="d-flex add-user-btn mt-4 df gap-1"
            style={{ width: "140px" }}
          >
            <img className="icon" src={add} alt="" /> Upload
            <input
              onChange={onSelectFile}
              type="file"
              accept="image/*"
              name=""
              id=""
            />
          </button>
        </div>
        <div className="col-12 col-md-7 mb-4 ">
          <div className="row">
            <div className="col-12 global mt-3">
              <p>Site Name</p>
              <input
                value={siteName}
                onChange={(e) => setSiteName(e.target.value)}
                type="text"
                placeholder=""
              />
            </div>
            <div className="col-12 global mt-3">
              <p>Web Url</p>
              <input
                value={siteUrl}
                onChange={(e) => setSiteUrl(e.target.value)}
                type="text"
                placeholder=""
              />
            </div>
            <div className="col-12 global mt-3">
              <p>Phone Number</p>
              <input
                disabled
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                type="text"
                placeholder=""
              />
            </div>
            <div className="col-12 global mt-3">
              <p>Email</p>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder=""
              />
            </div>
            <div className="col-12 global mt-3">
              <p>Address</p>
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                placeholder=""
              />
            </div>
            <div className="col-12 global mt-3">
              <p>Short Description</p>
              <textarea
                onChange={(e) => setDesc(e.target.value)}
                placeholder=" "
                value={desc}
              />
            </div>
            <div className="col-12 global mt-3">
              <button onClick={postGlobal}>Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
