import React, { useEffect, useState } from "react";
import "../Authentication.css";
// import MainLoader from "./MainLoader";
import blueLogo from "../../../../assets/logo/SnowtexBlue.svg";
import { Divider } from "@material-ui/core";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../Const/Url";
import { showToast } from "../../../Const/ToastHelper";
import Loader from "../../../Const/Loader";
// import { BASE_URL } from "../../../Const/Url";
// import { showToast } from "../";
const Login = ({ setHideToolbar }) => {
  const [loading, setLoading] = useState(true);
  const [countryCode, setCountryCode] = useState([
    {
      id: 1,
      phone_code: "+110",
    },
    {
      id: 2,
      phone_code: "+880",
    },
    {
      id: 3,
      phone_code: "+991",
    },
    {
      id: 4,
      phone_code: "+440",
    },
    {
      id: 5,
      phone_code: "+881",
    },
  ]);
  const [userCountryCode, setUserCountryCode] = useState("+880");
  const history = useHistory();
  const [numberValidation, setNumberValidation] = useState(null);
  const [passValidation, setPassValidation] = useState("");
  const [eye, setEye] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("is_logged_in")) {
      history.push("/dashboard");
    }
    setHideToolbar(true);
    return () => {
      setHideToolbar(false);
    };
  }, []);
  const changeEye = () => {
    setEye((prev) => !prev);
  };
  useEffect(() => {
    if (localStorage.getItem("is_logged_in")) {
      history.push("/");
    } else {
      history.push("/login");
    }
  }, []);
  useEffect(() => {
    const url = `${BASE_URL}api/v1/auth/country/`;
    const fetchQuote = async () => {
      const res = await fetch(url);
      const data = await res.json();
      setCountryCode(data.data.results);
      setLoading(false);
    };

    fetchQuote();
  }, []);
  const SignInData = () => {
    const data = new FormData();
    const phoneNumber = `${userCountryCode}${numberValidation}`;
    data.append("mobile", phoneNumber);
    data.append("password", passValidation);
    // data.append("mobile", '+8801722641299');
    // data.append("password", 'bablu@admin1');
    const url = `${BASE_URL}api/v1/auth/login/`;
    console.log('logindata',{
      mobile:phoneNumber,
      password:passValidation
    })
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          showToast("success", "Logged In Successfully.");
          // Store data's to local storage
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("access_token", res.data.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          if (localStorage.getItem("is_logged_in")) {
            history.push("/");
          }
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }
        console.log(err.request.response);
        showToast("error", message);
      });
  };
  // useEffect(() => {
  //   if (localStorage.getItem("is_logged_in")) {
  //     history.push("/");
  //   }
  // }, [localStorage.getItem("is_logged_in")]);

  // if (loading) {
  //   return <Loader />;
  // }
  console.log("number", isNaN(numberValidation) === true ? "text" : "number");
  return (
    <div className="login_main">
      <div>
        <div className="logo_image">
          <img className="img-fluid" src={blueLogo} alt="" />
        </div>
        <h3 className="login_text">Admin Login</h3>
        <div className="input_container">
          <span>Phone Number</span>
          <div className="number">
            <select
              onChange={(e) => setUserCountryCode(e.target.value)}
              name=""
              id=""
            >
              {countryCode?.map((country) => (
                <option
                  selected={country.phone_code === "+880"}
                  key={country.id}
                  value={country.phone_code}
                >
                  {country.phone_code}
                </option>
              ))}
            </select>
            <div className="divider"></div>
            <input
              type="text"
              onChange={(e) => setNumberValidation(e.target.value)}
              className="w-100"
            />
          </div>
          {isNaN(numberValidation) === true ? (
            <span>
              <RiErrorWarningLine /> Inter Number Only
            </span>
          ) : numberValidation?.length > 10 ? (
            <span>
              <RiErrorWarningLine /> Inter 10 Digits Only
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="input_container mt-3">
          <span>Password</span>
          <div className="number" style={{ position: "relative" }}>
            <input
              type={eye ? "text" : "password"}
              className="w-100"
              autoComplete="false"
              onChange={(e) => setPassValidation(e.target.value)}
            />
            <div onClick={() => changeEye()}>
              {eye ? (
                <AiOutlineEye
                  style={{ cursor: "pointer" }}
                  size={24}
                  color="#0000001A"
                />
              ) : (
                <AiOutlineEyeInvisible
                  style={{ cursor: "pointer" }}
                  size={24}
                  color="#0000001A"
                />
              )}
            </div>
          </div>
          {passValidation?.length > 0 && passValidation?.length < 8 && (
            <span>
              <RiErrorWarningLine /> Minimum 8 characters
            </span>
          )}
        </div>
        <div className="create_redirect">
          <span></span>
          <span
            className=" colord"
            style={{ textDecoration: "underline" }}
            onClick={() => history.push(`/forget_pass`)}
          >
            {" "}
            Forgot Password?
          </span>
        </div>
        <div className="input_container" style={{ marginTop: "15px" }}>
          <button
            // onClick={()=>{
            //   localStorage.setItem("is_logged_in", true);
            //   history.push("/")
            // }}
            onClick={SignInData}
            className="w-100  btn-outline-none"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
