import { Lock, MagnifyingGlass, Power, UserCircle, X } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../CommonDesgin/PageHeader";
import select from "../../assets/img/select.svg";
import add from "../../assets/img/add.svg";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import { showToast } from "../Const/ToastHelper";
import PmLoader from "../CommonDesgin/PmLoader";
import { MenuItem } from "react-pro-sidebar";
import { Menu } from "@material-ui/core";
import { logout_func3 } from "../Const/logoutCommon";

const ViewProfile = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const CommonData = {
    title: "Profile",
    path: "Dashboard/ Profile",
    add: false,
    btnData: "Add New Admin",
    search: false,
  };
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const [preview, setPreview] = useState(null);
  const inputRef = useRef();
  const onSelectFile = (event) => {
    setUploaded(event.target.files[0]);
  };
  const removeImg = () => {
    setUploaded(null);
    setPreview(null);
  };
  
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  
  const getProfile = () => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        setFirstName(res.data.data.first_name);
        setLastName(res.data.data.last_name);
        setAddress(res.data.data?.address);
        setEmail(res.data.data.email);
        setMobile(res.data.data.mobile);
        setImage(res.data.data.image_url);
        setData(res.data.data);
        localStorage.setItem('userProfile', JSON.stringify(res.data.data))
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history)
        }
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }

        showToast("error", message);
      });
  };
  useEffect(() => {
    getProfile();
  }, []);
  
  const postImage = () => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    const url2 = `${BASE_URL}api/v1/auth/profile/`;
    const data1 = new FormData();
    data1.append("document", uploaded);
    data1.append("doc_type", 0);
    if (uploaded !== null) {
      axios
        .post(url1, data1)
        .then((res) => {
          console.log(res.data.data);
          if (res.data.status) {
            axios
              .post(url2, {
                first_name: firstName,
                last_name: lastName,
                image: res.data.data.id,
                bio: data.bio,
                address: address,
              })
              .then((res) => {
                if (res.data.status) {
                  console.log(res.data);
                  showToast("success", "Data Updated Successfully.");
                  getProfile();
                  
                  setUploaded(null);
                  localStorage.setItem('userProfile', JSON.stringify(data))
                }
              })
              .catch((err) => {
                const message = JSON.parse(err.request.response).message;
                const errorMsg = JSON.parse(err.request.response).errors;
                for (let value of Object.values(errorMsg)) {
                  showToast("error", value[0]);
                }

                showToast("error", message);
              });
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }
          console.log(err.request.response);
          showToast("error", message);
        });
    } else {
      axios
        .post(url2, {
          first_name: firstName,
          last_name: lastName,
          image: data.image,
          bio: data.bio,
          address: address,
        })
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            showToast("success", "Data Updated Successfully.");
            
            getProfile();
            setUploaded(null);
            localStorage.setItem("userProfile", JSON.stringify(data));
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }

          showToast("error", message);
        });
    }
  };

  if (loading) {
    return <PmLoader />;
  }

  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <div>
      <div className="upper">
        <div>
          <h5>{CommonData?.title}</h5>
          <p>{CommonData?.path}</p>
        </div>
        <div
          className="upper_user"
          aria-controls={open2 ? "long-menu" : undefined}
          aria-expanded={open2 ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick2}
        >
          <img src={data ? data?.image_url : select} alt="" />
        </div>
      </div>
      <>
        <Menu
          id="long-menu"
          className="pop_over3"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              width: "22ch",
              marginRight: "10ch",
              top: "89px !important",
              padding: "8px ",
            },
          }}
        >
          {/* <MenuItem className="privacy my-2" onClick={()=>{
            history.push('/view-profile')
            handleClose2()
          }}>
            {" "}
            <UserCircle
              color="#1F5DA0"
              size={24}
              weight="bold"
              className="me-3"
            />
            View Profile
          </MenuItem> */}
          <MenuItem className="privacy my-3 mx-2" onClick={()=>{
            history.push('/change-password')
            handleClose2()
          }}>
            {" "}
            <Lock color="#1F5DA0"
              size={24} weight="bold" className="me-3" />
            Change Password
          </MenuItem>
          <MenuItem className="privacy my-3 mx-2" onClick={()=>{
            logout_func3(history)
            handleClose2()
          }}>
            {" "}
            <Power color="#1F5DA0"
              size={24} weight="bold" className="me-3" />
            Logout
          </MenuItem>
        </Menu>
      </>
      <div className="lower mt-4">
        {CommonData.search === true && (
          <div className="input">
            <input type="text" placeholder="Search ..." />
            <button>
              <MagnifyingGlass color="white" size={28} weight="bold" />
            </button>
          </div>
        )}

        {CommonData.add === true && (
          <div>
            <button className="d-flex add-user-btn">
              <img className="icon" src={add} alt="" />
              <span className="ms-2">{CommonData.btnData}</span>
            </button>
          </div>
        )}
      </div>
    </div>
      <div className="content_div bg-white row gap-2">
        <div className="col-12 col-md-4 picture">
          <p>Profile Picture</p>
          <div className="select_img">
            <img
              style={{ width: "80%", height: "90%", objectFit: "cover" }}
              src={uploaded ? URL.createObjectURL(uploaded) : image}
              alt=""
            />
            {uploaded && (
              <div className="cross" onClick={removeImg}>
                <X size={20} weight="thin" />
              </div>
            )}
           
          </div>
          <div
            className="d-flex add-user-btn mt-4 df gap-1"
            style={{ width: "140px" }}
          >
            <img className="icon" src={add} alt="" /> Upload
            <input
              onChange={onSelectFile}
              ref={inputRef}
              type="file"
              accept="image/*"
              name=""
              id=""
            />
          </div>
        </div>
        <div className="col-12 col-md-7 mb-4 pb-4">
          <div className="row">
            <div className="col-12 col-md-6 global">
              <p>First Name</p>
              <input
                type="text"
                placeholder="Enter First Name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </div>
            <div className="col-12 col-md-6 global">
              <p>Last Name</p>
              <input
                type="text"
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>

            <div className="col-12  mt-3">
              <p>Phone Number</p>
              <input
                className="input"
                type="text"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
                disabled
              />
            </div>
            <div className="col-12 global mt-3">
              <p>Email</p>
              <input
                type="email"
                placeholder="Type Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled
              />
            </div>
            <div className="col-12 global mt-3 mb-2">
              <p>Address</p>
              <input
                type="text"
                placeholder="Enter Address "
                onChange={(e) => setAddress(e.target.value)}
                value={address ? address : ""}
              />
            </div>

            <div className="col-12 global2 mt-3">
              <button className="back" onClick={() => history.push("/")}>
                Cancel
              </button>
              <button className="ms-2  save" onClick={postImage}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
