import axios from "axios";
import * as Types from '../types/Types'

// --------------------Pagination Start-----------------------
export const paginationLimit = (limit) => {
    return {
      type: Types.PAGINATION_lIMIT,
      payload: limit,
    };
  };
  export const setpaginationName = (name) => {
    return {
      type: Types.PAGINATION_NAME,
      payload: name,
    };
  };
  export const setLoadLeads = (isTrue) => {
    return {
      type: Types.IS_LOAD_LEADS,
      payload: isTrue,
    };
  };
  
  export const paginationPrevious = () => {
    return {
      type: Types.PAGINATION_PREVIOUS,
    };
  };
  
  export const paginationNext = () => {
    return {
      type: Types.PAGINATION_NEXT,
    };
  };
  
  export const paginationChangeCurrent = (current) => {
    return {
      type: Types.PAGINATION_CHANGE_CURRENT,
      payload: current,
    };
  };
  
  export const PaginationLoading = (is_ON) => {
    return {
      type: Types.PAGINATION_LOADING,
      payload: is_ON,
    };
  };
  
  export const PaginationSuccess = (data) => {
    return {
      type: Types.PAGINATION_SUCCESS,
      payload: data,
    };
  };
  export const PaginationError = (error) => {
    return {
      type: Types.PAGINATION_ERROR,
      payload: error,
    };
  };
  export const PaginationUrl = (url) => {
    return {
      type: Types.PAGINATION_URL,
      payload: url,
    };
  };
  
  export const GetPaginationData = (url) => {
    console.log(url,'url========================')
    return (dispatch) => {
      url.length > 0 &&
        axios
          .get(url)
          .then((success) => {
            dispatch(
              PaginationSuccess(
                success.data.data === undefined ? [] : success.data.data
              )
            );
            dispatch(PaginationLoading(false));
          })
          .catch((error) => {
            dispatch(PaginationError(error.message));
            dispatch(PaginationLoading(false));
          });
      dispatch(setLoadLeads(true));
    };
  };
  
  // --------------------Pagination End-----------------------