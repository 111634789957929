import axios from "axios";
import { Envelope, LockKey, LockKeyOpen, Phone, User, X } from "phosphor-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import user from "../../../assets/img/viewuser.svg";
import PmLoader from "../../CommonDesgin/PmLoader";
import { logout_func3 } from "../../Const/logoutCommon";
import { showToast } from "../../Const/ToastHelper";
import { BASE_URL } from "../../Const/Url";

const EditModal = ({ show4, setShow4, handleClose4, singleUser ,apiFunction}) => {
  const history = useHistory();
  const [numberValidation, setNumberValidation] = useState("+880");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [uploaded, setUploaded] = useState(null);
  const [preview, setPreview] = useState(null);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const onSelectFile = (event) => {
    setUploaded(event.target.files[0]);
  };
  const removeImg = () => {
    setUploaded(null);
    setPreview(null);
  };

  // apiFunction(
  // `${BASE_URL}api/v1/auth/user/?is_active=true`
  // );
  const close = () => {
    removeImg();
    handleClose4();
  };
  const getProfile = () => {
    const url = `${BASE_URL}api/v1/auth/user/${singleUser.id}/`;
    axios
      .get(url)
      .then((res) => {
        setFirstName(res.data.data.first_name);
        setLastName(res.data.data.last_name);
        setEmail(res.data.data.email);
        setUserData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }

        // showToast("error", message);
      });
  };
  useEffect(() => {
    getProfile();
  }, [singleUser]);
  console.log("singleUser", {
    firstName: firstName,
    lastName: lastName,
    email: email,
  });
  // if (loading) {
  //   return <PmLoader />;
  // }
  const updateProfile = () => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    const url2 = `${BASE_URL}api/v1/auth/user/${singleUser.id}/`;
    const data1 = new FormData();
    const data2 = new FormData();
    const data3 = new FormData();
    data1.append("document", uploaded);
    data1.append("doc_type", 0);
    if (uploaded !== null) {
      axios
        .post(url1, data1)
        .then((res) => {
          console.log(res.data.data);
          if (res.data.status) {
            data2.append("first_name", firstName);
            data2.append("last_name", lastName);
            data2.append("image", res.data.data.id);
            data2.append("mobile", singleUser?.mobile);
            data2.append("email", email);
            data2.append("password", null);
            data2.append("confirm_password", null);
            axios
              .put(url2, data2)
              .then((res) => {
                if (res.data.status) {
                  console.log(res.data);
                  showToast("success", "Data Updated Successfully.");
                  setUploaded(null);
                  apiFunction()
                  handleClose4();
                }
              })
              .catch((err) => {
                const message = JSON.parse(err.request.response).message;
                const errorMsg = JSON.parse(err.request.response).errors;
                for (let value of Object.values(errorMsg)) {
                  showToast("error", value[0]);
                }

                showToast("error", message);
              });
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }
          console.log(err.request.response);
          showToast("error", message);
        });
    } else {
      data3.append("first_name", firstName);
      data3.append("last_name", lastName);
      data3.append("image", singleUser.image);
      data3.append("mobile", singleUser?.mobile);
      data3.append("email", email);
      data3.append("password", null);
      data3.append("confirm_password", null);
      axios
        .put(url2, data3)
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            showToast("success", "Data Updated Successfully.");
            apiFunction()
            handleClose4();
            setUploaded(null);
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }

          showToast("error", message);
        });
    }
  };
  return (
    <Modal size="lg" show={show4} centered onHide={close}>
      <div className="d-flex justify-content-end ">
        <button
          onClick={close}
          className="modal-btn"
          style={{
            border: "1px solid #263238",
            outline: "none",
            marginTop: "-15px",
            marginRight: "-15px",
          }}
        >
          <X size={26} color={"#263238"} weight="thin" />
        </button>
      </div>
      {loading ? (
        <PmLoader />
      ) : (
        <Modal.Body>
          <div className="login_main">
            <div>
              <h3 className="login_text">Update User </h3>
              <div>
                <div className="logo_image ">
                  <div className="user_img" style={{ position: "relative" }}>
                    <img
                      style={{
                        width: "110px",
                        height: "110px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        border: "1px solid skyblue",
                      }}
                      className="img-fluid"
                      src={
                        uploaded
                          ? URL.createObjectURL(uploaded)
                          : singleUser.image_url
                      }
                      alt=""
                    />
                    <input
                      onChange={onSelectFile}
                      className="inp"
                      type="file"
                    />
                    {uploaded && (
                      <button
                        onClick={removeImg}
                        style={{
                          border: "1px solid skyblue",
                          outline: "none",
                          position: "absolute",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          right: "5px",
                          top: "5px",
                          zIndex: "20000",
                          cursor: "pointer",
                        }}
                      >
                        <X size={18} color={"#263238"} weight="thin" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="input_container create mt-3 d-flex justify-content-between name">
                  <div className="number number2">
                    <div className="icons-div">
                      <User size={24} color="white" weight="light" />
                    </div>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="number number2">
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="input_container create mt-3">
                  <div className="number">
                    <div className="icons-div">
                      <Envelope size={24} color="white" weight="light" />
                    </div>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      name=""
                      id=""
                      value={email}
                    />
                  </div>
                  {!email?.match(mailformat) && email?.length > 0 && (
                    <span>
                      <RiErrorWarningLine /> invalid Email
                    </span>
                  )}
                </div>
                <div className="input_container create mt-3 d-none">
                  <div className="number">
                    <div className="icons-div">
                      <Phone size={24} color="white" weight="light" />
                    </div>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      name=""
                      id=""
                      autocomplete="off"
                      value={numberValidation}
                      onChange={(e) => setNumberValidation(e.target.value)}
                    />
                  </div>
                  {numberValidation?.length > 4 &&
                    numberValidation?.length !== 14 && (
                      <span>
                        <RiErrorWarningLine /> Must Be 11 characters
                      </span>
                    )}
                </div>
                {/* <div className="input_container create mt-3">
                <div className="number">
                  <div className="icons-div">
                    <LockKey size={24} color="white" weight="light" />
                  </div>
                  <input
                    type={eye ? "text" : "password"}
                    onChange={(e) => setPassValidation(e.target.value)}
                    placeholder="Password"
                    name=""
                    id=""
                    autocomplete="off"
                  />
                  <div onClick={() => changeEye()}>
                    {eye ? (
                      <AiOutlineEye
                        style={{ cursor: "pointer" }}
                        size={24}
                        color="#0000001A"
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={{ cursor: "pointer" }}
                        size={24}
                        color="#0000001A"
                      />
                    )}
                  </div>
                </div>
                {passValidation?.length > 0 && passValidation?.length < 8 && (
                  <span>
                    <RiErrorWarningLine /> Minimum 8 characters
                  </span>
                )}
              </div> */}

                {/* <div className="input_container create mt-3">
                <div className="number">
                  <div className="icons-div">
                    <LockKeyOpen size={24} color="white" weight="light" />
                  </div>
                  <input
                    autocomplete="off"
                    type={eye2 ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={(e) => setFinalPass(e.target.value)}
                    name=""
                    id=""
                  />
                  <div onClick={() => changeEye2()}>
                    {eye2 ? (
                      <AiOutlineEye
                        style={{ cursor: "pointer" }}
                        size={24}
                        color="#0000001A"
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={{ cursor: "pointer" }}
                        size={24}
                        color="#0000001A"
                      />
                    )}
                  </div>
                </div>
                {finalPass?.length > 0 && finalPass !== passValidation && (
                  <span>
                    <RiErrorWarningLine />
                    Password Didn't Match !
                  </span>
                )}
              </div> */}

                <div className="input_container" style={{ marginTop: "37px" }}>
                  <button
                    onClick={updateProfile}
                    className="w-100  btn-outline-none"
                  >
                    Update User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default EditModal;
