import React from "react";
import PageHeader from "../../CommonDesgin/PageHeader";
import user1 from "../../../assets/img/1.svg";
import user2 from "../../../assets/img/2.svg";
import user3 from "../../../assets/img/3.svg";
import { CaretLeft, CaretRight, Check } from "phosphor-react";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { BiPencil } from "react-icons/bi";
import { TbTrash } from "react-icons/tb";
import { Checkbox } from "@mui/material";
import { useEffect } from "react";
import { logout_func3 } from "../../Const/logoutCommon";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../Const/Url";
import PmLoader from "../../CommonDesgin/PmLoader";
import Table404 from "../../Const/Table404";
import PagginationForAll from "../../CommonDesgin/PagginationForAll";
import ViewModal from "../ActiveUser/ViewModal";
import swal from "sweetalert";
import PageHead2 from "../../CommonDesgin/PageHead2";

const InactiveUser = () => {
  const [show, setShow] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const handleClose = () => {
    setSingleUser({});
    setShow(false);
  };
  const handleShow = (data) => {
    setSingleUser(data);
    setShow(true);
  };
  const CommonData = {
    title: "Inactive User",
    path: "Dashboard/ User/ Inactive User",
    add: false,
    btnData: "",
    search: true,
  };
  const tableData = [
    {
      id: 1,
      name: "Alex Robert",
      phone: "+8801363738735",
      email: "alexrobert32@gmail.com",
      img: user1,
    },
    {
      id: 2,
      name: "Ashraf Hossain",
      phone: "+8801763732735",
      email: "ashrafhossain@gmail.com",
      img: user2,
    },
    {
      id: 3,
      name: "Blake Morison",
      phone: "+8801963738935",
      email: "blakemorison@gmail.com",
      img: user3,
    },
    {
      id: 4,
      name: "Blake Morison",
      phone: "+8801963738935",
      email: "blakemorison@gmail.com",
      img: user1,
    },
  ];

  //clean up testing
  const history = useHistory();
  const [search,setSearch] = useState('')
  const [activeUser, setActiveUser] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [total_item, setTotal_item] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 15;
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setActiveUser(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
      });
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}api/v1/auth/user/?is_active=false&limit=${limit}&offset=${offset}&search=${search}`
    );
  }, [pageNumber,search]);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);

    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  const approveUser = (id) => {
    swal({
      title: `Are you sure?`,
      text: "Once Click, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/auth/user/${id}/approve/`;
        axios
          .post(url, {
            is_active: true,
          })
          .then(() => {
            setSearch('')
            apiFunction(
              `${BASE_URL}api/v1/auth/user/?is_active=false&limit=${limit}&offset=${offset}`
            );
            swal("Poof! User has been Approved!", {
              icon: "success",
            });
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };
  
  if (loading) {
    return <PmLoader />;
  }
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <PageHead2 CommonData={CommonData} search={search} setSearch={setSearch} />
      <div className="mt-4">
        <div class="table-responsive">
          <table class="table">
            <thead
              style={{
                backgroundColor: "#1f5da0",
                color: "white",
                fontSize: "18px",
              }}
            >
              <tr className="active_tr">
                <th>
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                    color="default"
                  />
                </th>
                <th>PROFILE PICTURE</th>
                <th>FULL NAME</th>
                <th>PHONE NUMBER</th>
                <th>EMAIL</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {activeUser.length > 0 && total_item ? (
                activeUser?.map((data, index) => (
                  <tr
                    key={index}
                    className={`active_tr ${
                      index % 2 === 0 ? "bg-even" : "bg-odd"
                    }`}
                  >
                    <td>
                      <Checkbox
                        sx={{
                          color: "gray",
                          "&.Mui-checked": {
                            color: "gray",
                          },
                        }}
                        color="default"
                      />
                    </td>
                    <td>
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={data.image_url}
                        alt=""
                      />
                    </td>
                    <td>
                      {data.first_name} {data?.last_name}
                    </td>
                    <td>{data.mobile}</td>
                    <td>{data.email}</td>
                    <td>
                      <div className="d-flex justify-content-center gap-2">
                        <div
                          onClick={() => handleShow(data)}
                          className="edit-del blue"
                          style={{
                            background: "#A0A0A0",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <RemoveRedEyeOutlined
                            style={{ color: "white" }}
                            className="icon"
                          />
                        </div>

                        <div
                        onClick={()=>{approveUser(data.id)}}
                          className="edit-del green"
                          style={{
                            background: "#00C86B",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <Check color="white" className="icon" />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <Table404 />
              )}
            </tbody>
          </table>
        </div>
      </div>
      {total_item !== 0 && (
        <PagginationForAll
          prevUrl={prevUrl}
          nextUrl={nextUrl}
          pageCount={pageCount}
          pageNumber={pageNumber}
          handlePaginationNext={handlePaginationNext}
          handlePaginationPrevious={handlePaginationPrevious}
          handleChangeCurrentPage={handleChangeCurrentPage}
          total_item={total_item}
          page_show_start={page_show_start}
          this_page_items_show={this_page_items_show}
        />
      )}
      <ViewModal
        singleUser={singleUser}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
      />
    </div>
  );
};

export default InactiveUser;
