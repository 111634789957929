import React, { useEffect, useState } from "react";

import "../Authentication.css";
import { useHistory } from "react-router-dom";
import verify from "../../../../assets/img/Forget.svg";
import tic from "../../../../assets/img/lock.svg";

import { RiErrorWarningLine } from "react-icons/ri";
import { BASE_URL } from "../../../Const/Url";
import { showToast } from "../../../../utils/ToastHelper";
import axios from "axios";
import { Key } from "phosphor-react";

export default function ForgatePassword({ setHideToolbar }) {
  const [numberValidation, setNumberValidation] = useState(null);
  const [passValidation, setPassValidation] = useState("");
  const [eye, setEye] = useState(true);
  const [activeOtp, setActiveOtp] = useState(0);
  const [finalOtp, setFinalOtp] = useState(null);
  const [countryCode, setCountryCode] = useState([
    {
      id: 1,
      phone_code: "+110",
    },
    {
      id: 2,
      phone_code: "+880",
    },
    {
      id: 3,
      phone_code: "+991",
    },
    {
      id: 4,
      phone_code: "+440",
    },
    {
      id: 5,
      phone_code: "+881",
    },
  ]);
  useEffect(() => {
    const url = `${BASE_URL}api/v1/auth/country/`;
    const fetchQuote = async () => {
      const res = await fetch(url);
      const data = await res.json();
      setCountryCode(data.data.results);
      
    };

    fetchQuote();
  }, []);
  const [userCountryCode, setUserCountryCode] = useState("+880");
  const history = useHistory();
  useEffect(() => {
    setHideToolbar(true);
    return () => {
      setHideToolbar(false);
    };
  }, []);
  const goToForgot=()=>{
    const data = new FormData();
    const phoneNumber = `${userCountryCode}${numberValidation}`;
    data.append("mobile", phoneNumber);
    const url = `${BASE_URL}api/v1/auth/forget/password/`;
    axios
  .post(url, data)
  .then((res) => {
    if (res.data.status) {
      localStorage.setItem("userNumberOtp", phoneNumber);
      showToast("success", "Otp sended.");
      history.push('/reset_pass')
    }
  })
  .catch((err) => {
    const message = JSON.parse(err.request.response).message;
    const errorMsg = JSON.parse(err.request.response).errors;
    for (let value of Object.values(errorMsg)) {
      showToast("error", value[0]);
    }
    
    showToast("error", message);
  });

  
  }

  // For Modals

  return (
    <>
      <div className="login_main">
        <div>
          <div className="logo_image" style={{ position: "relative" }}>
            <img className="img-fluid" src={verify} alt="" />
            <img className="img-fluid tic" src={tic} alt="" />
          </div>
          <h3 className="login_text">Forgot Your Password? </h3>
          <div className="verify-main">
            <span className="verify-text">
              Please enter your phone number to <br />
              receive a verification code.
            </span>
          </div>
          
          <div className="input_container">
            <span style={{marginBottom:'5px'}}>Phone Number</span>
            <div className="number mt-1">
              <select
                onChange={(e) => setUserCountryCode(e.target.value)}
                name=""
                id=""
              >
                {countryCode?.map((country) => (
                  <option
                    selected={country.phone_code === "+880"}
                    key={country.id}
                    value={country.phone_code}
                  >
                    {country.phone_code}
                  </option>
                ))}
              </select>
              <div className="divider"></div>
              <input
                type="text"
                onChange={(e) => setNumberValidation(e.target.value)}
                className="w-100"
              />
            </div>
            {isNaN(numberValidation) === true ? (
              <span>
                <RiErrorWarningLine /> Inter Number Only
              </span>
            ) : numberValidation?.length > 10 ? (
              <span>
                <RiErrorWarningLine /> Inter 10 Digits Only
              </span>
            ) : (
              ""
            )}
            <div className="input_container" style={{ marginTop: "37px" }}>
              <button
                className="w-100  btn-outline-none"
                // onClick={() => history.push('/reset_pass')}
                onClick={goToForgot}
              >
                Send Request
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
