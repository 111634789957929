import { combineReducers } from "redux";


import LogInReducer from "../../modules/authentication/_redux/reducer/LogInReducer";
import { PaginationReducer } from "../../modules/Pagination/_redux/reducer/PaginationReducer";


// combine all of the reducers here
const rootReducer = combineReducers({
  logInInfo: LogInReducer,
  Pagination:PaginationReducer

 
});

export default rootReducer;
