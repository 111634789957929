import React, { useEffect } from "react";
import PageHeader from "../../CommonDesgin/PageHeader";
import select from "../../../assets/img/select.svg";
import { useState } from "react";
import { Smiley, X } from "phosphor-react";
import add from "../../../assets/img/add.svg";
import useWebSocket, { ReadyState } from "react-use-websocket";

import EmojiPicker from "emoji-picker-react";
import { useRef } from "react";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import { BASE_URL } from "../../Const/Url";
import { logout_func3 } from "../../Const/logoutCommon";
import axios from "axios";
import { showToast } from "../../Const/ToastHelper";
import PmLoader from "../../CommonDesgin/PmLoader";

const EditGroup = () => {
  const { id } = useParams();
  const [singleGroup, setSingleGroup] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [uploaded, setUploaded] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previousMember, setPreviousMember] = useState([]);
  const onSelectFile = (event) => {
    setUploaded(event.target.files[0]);
  };
  const removeImg = () => {
    setUploaded(null);
    setPreview(null);
  };
  const CommonData = {
    title: "Edit Group",
    path: "Dashboard/ Conversation/ Add Group ",
    add: false,
    btnData: "Add New Group",
    search: false,
  };
  const getPrevMembers = () => {
    const url = `${BASE_URL}api/v1/chat/admin/conversation/${id}/group_contacts/`;
    axios
      .get(url)
      .then((res) => {
        
        const results = res.data.data.results
        setPreviousMember(results?.map((result) => {
          return {
            value: result.id,
            label: `${result?.first_name} ${result?.last_name}`,
          };
          
        }));
        setLoading(false)
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };
  // console.log(previousMember, "members");

  const getSingleGroup = () => {
    const url = `${BASE_URL}api/v1/chat/admin/conversation/${id}/`;
    axios
      .get(url)
      .then((res) => {
        setInputStr(res.data?.data?.group_name);
        setSingleGroup(res.data.data);
        // setLoading(false);
        console.log(res.data.data, "convodata");
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }

        // showToast("error", message);
      });
  };
  useEffect(() => {
    getPrevMembers();
    getSingleGroup();
  }, []);
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const onEmojiClick = (emojiData, event) => {
    setInputStr(
      (prevInput) =>
        prevInput + String?.fromCodePoint(`0x${emojiData?.unified}`)
    );
    setShowPicker(false);
  };
  const [inputQuery, setInputQuery] = useState("");
  const [selected, setSelected] = useState([]);
  const token = localStorage.getItem("access_token");
  const [socket, setSocket] = useState(
    `wss://echat-backend.devsstream.com/ws/chat/${token}/`
  );
  useWebSocket(socket, {
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
  });
  useWebSocket(socket, {
    onClose: () => {
      console.log("WebSocket connection Disconected.");
    },
  });
  const inputSearch = (inputValue) => {
    setInputQuery(inputValue);
  };
  const handleMultipleInputChange = (newValue, actionMeta) => {
    setSelected(newValue);
  };
  // console.log(selected,'members selected')
  console.log("group", {
    query: inputQuery,
    member: selected,
  });
  const [members, setMembers] = useState([]);
  const getMembers = () => {
    const url = `${BASE_URL}api/v1/auth/user/?search=${inputQuery.toLowerCase()}`;
    axios
      .get(url)
      .then((res) => {
        const results = res.data.data.results;
        setMembers(
          results?.map((result) => {
            return {
              value: result.id,
              label: `${result?.first_name} ${result?.last_name}`,
            };
          })
        );
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
      });
  };
  useEffect(() => {
    if (inputQuery?.length >= 2) {
      getMembers();
    }
  }, [inputQuery]);
  console.log(selected, "members selected testData");
  const addGroup = () => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    const url2 = `${BASE_URL}api/v1/chat/admin/conversation/${id}/`;
    if (inputStr === "") {
      showToast("error", "Inter Group Name Please!");
    } 
    else if (selected?.length === 0  || previousMember?.length === 0) {
      showToast("error", "Add Group Member Please!");
    } else if (selected?.length < 3 || previousMember?.length < 3) {
      showToast("error", "Add Minimum 3 Member Please!");
    } 
    else {
      if (uploaded !== null) {
        const data1 = new FormData();
        data1.append("document", uploaded);
        data1.append("doc_type", 0);
        axios
          .post(url1, data1)
          .then((res) => {
            console.log(res.data.data);
            if (res.data.status) {
              const testData2 = {
                group_image: res.data.data.id,
                group_name: inputStr,
                convo_type: 1,
                admins: [1],
                is_active: true,
                users: selected?.length > 0 ? selected?.map((s) => s.value) : previousMember?.map((s) => s.value),
              };
              axios
                .put(url2, testData2)
                .then((res) => {
                  console.log(res,'testData res 192')
                  console.log('testData ',testData2)
                  setLoading(true);
                  if (res.data.status) {
                    
                    showToast("success", "Group Updated");
                    history.push("/group");
                  }
                })
                .catch((err) => {
                  const message = JSON.parse(err.request.response).message;
                  const errorMsg = JSON.parse(err.request.response).errors;
                  for (let value of Object.values(errorMsg)) {
                    showToast("error", value[0]);
                  }
                  console.log(err.request.response);
                  showToast("error", message);
                });
            }
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
            }
            console.log(err.request.response);
            showToast("error", message);
          });
      } else {
        const testData = {
          group_image: singleGroup?.group_image,
          group_name: inputStr,
          convo_type: 1,
          admins: [1],
          is_active: true,
          users: selected?.length > 0 ? selected?.map((s) => s.value) : previousMember?.map((s) => s.value),
        };
        axios
          .put(url2, testData)
          .then((res) => {
            console.log(res,'testData res 233')
            console.log('testData 231',testData)
            setLoading(true);
            if (res.data.status) {
              console.log('testData 231',testData)
              showToast("success", "Group Updated ");
              history.push("/group");
            }
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
            }
            console.log(err.request.response);
            showToast("error", message);
          });
        // console.log(testData,'testData')
        // console.log(singleGroup,'testData singleGrp')
      }
    }
  };
  useEffect(() => {
    const newSocket = new WebSocket(
      `wss://echat-backend.devsstream.com/ws/chat/${token}/`
    );
    setSocket(newSocket);
    newSocket.onmessage = (event) => {
      console.log(event, "WebSocket connection established.33");
    };
  }, [inputStr]);
  if (loading) {
    return <PmLoader />;
  }
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <PageHeader CommonData={CommonData} />
      <div className="content_div bg-white row gap-2">
        <div className="col-12 col-md-4 picture">
          <p>Profile Picture</p>
          <div className="select_img">
            <img
              style={{ width: "80%", height: "90%", objectFit: "cover" }}
              src={
                uploaded
                  ? URL.createObjectURL(uploaded)
                  : singleGroup?.group_image_url
              }
              alt=""
            />
            {uploaded && (
              <div className="cross" onClick={removeImg}>
                <X size={20} weight="thin" />
              </div>
            )}
          </div>
          <div
            className="d-flex add-user-btn mt-4 df gap-1"
            style={{ width: "140px" }}
          >
            <img className="icon" src={add} alt="" /> Upload
            <input
              onChange={onSelectFile}
              type="file"
              accept="image/*"
              name=""
              id=""
            />
          </div>
        </div>
        <div className="col-12 col-md-7 mb-4 pb-4">
          <div className="row">
            <div className="col-12 global mt-3 input_container mt-3">
              <p>Group Name</p>
              <div
                className="number"
                style={{ position: "relative", width: "100%" }}
              >
                <input
                  type={"text"}
                  className="w-100"
                  autoComplete="false"
                  style={{ border: "none", outline: "none" }}
                  onChange={(e) => setInputStr(e.target.value)}
                  placeholder="Group Name"
                  value={inputStr}
                />
                <div>
                  {/* <Smiley
                    onClick={() => setShowPicker(!showPicker)}
                    style={{ cursor: "pointer" }}
                    size={26}
                    color="#0000001A"
                    weight="bold"
                  /> */}
                  {showPicker && (
                    <EmojiPicker
                      //   ref={buttonRef}
                      // pickerStyle={{ position: "absolute", top: "60px" ,zIndex:'200000'}}
                      onEmojiClick={onEmojiClick}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 global mt-3 input_container mt-3">
              <p>Members</p>
              <div style={{ zIndex: "-200000" }}>
                <Select
                  closeMenuOnSelect={false}
                  //   defaultValue={[colourOptions[4], colourOptions[5]]}
                  isMulti
                  options={members}
                  placeholder={"Search Members"}
                  onInputChange={inputSearch}
                  onChange={handleMultipleInputChange}
                  isClearable
                  defaultValue={previousMember}
                />
              </div>
            </div>
            <div className="col-12 global mt-3 input_container mt-3">
              <span
                className="selected_members mt-4"
                style={{ textAlign: "start" }}
              >
                Selected Members : {selected?.length ? selected?.length : previousMember?.length}{" "}
              </span>
            </div>
            <div className="col-12 global2 mt-3">
              <button className="back" onClick={() => history.push("/group")}>
                Cancel
              </button>
               <button className="ms-2  save" onClick={addGroup}>
                Update Group
              </button>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGroup;
