export const PAGINATION_NAME = "PAGINATION_NAME"
export const PAGINATION_lIMIT = "PAGINATION_lIMIT"
export const PAGINATION_NEXT = "PAGINATION_NEXT"
export const PAGINATION_PREVIOUS = "PAGINATION_PREVIOUS"
export const PAGINATION_CHANGE_CURRENT = "PAGINATION_CHANGE_CURRENT"
export const GET_PAGINATION_DATA = "GET_PAGINATION_DATA"
export const PAGINATION_LOADING = "PAGINATION_LOADING";
export const PAGINATION_SUCCESS = "PAGINATION_SUCCESS";
export const PAGINATION_ERROR = "PAGINATION_ERROR";
export const PAGINATION_URL = "PAGINATION_URL";
export const SET_LEADS_UPDATED = "SET_LEADS_UPDATED"
export const IS_LOAD_LEADS = "IS_LOAD_LEADS";