import { CaretLeft, CaretRight, Lock, MagnifyingGlass, Power, UserCircle } from "phosphor-react";
import React from "react";
import PageHeader from "../../CommonDesgin/PageHeader";
import user1 from "../../../assets/img/1.svg";
import user2 from "../../../assets/img/2.svg";
import user3 from "../../../assets/img/3.svg";
import { TbTrash } from "react-icons/tb";
import { BiPencil } from "react-icons/bi";
import {  RemoveRedEyeOutlined } from "@material-ui/icons";
import add from "../../../assets/img/add.svg";
import userDemo from "../../../assets/img/userdemo.svg";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";
import { isStaff, logout_func3 } from "../../Const/logoutCommon";
import PmLoader from "../../CommonDesgin/PmLoader";
import swal from "sweetalert";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Table404 from "../../Const/Table404";
import PagginationForAll from "../../CommonDesgin/PagginationForAll";
import ViewModal from "../../ChatUser/ActiveUser/ViewModal";
const AdminStaff = () => {
 
  const history = useHistory()
  const [show, setShow] = useState(false);
  const [singleUser,setSingleUser] = useState({})
  const handleClose = () => {
    setSingleUser({})
    setShow(false)
  };
  const handleShow = (data) => {
    setSingleUser(data)
    setShow(true)
  };
  console.log('singleUser',singleUser)
  const CommonData = {
    title: "Admin and Staff List",
    path: "Dashboard/ Administration/ Admin and Staff/ Admin and Staff List",
    add: true,
    btnData: "Add New Admin",
    search: true,
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  //clean up testing 
  const [search,setSearch] = useState('')
 const [staff,setStaff] = useState([])
 const [totalData, setTotalData] = useState(0);
 const [total_item, setTotal_item] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 15;
  const item_limit = pageNumber * limit;
  const this_page_items_show = item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setStaff(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history)
        }
      });
  };

  useEffect(()=>{
    // apiFunction(`${BASE_URL}v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}`)
    apiFunction(`${BASE_URL}api/v1/auth/staff/?limit=${limit}&offset=${offset}&search=${search}`)
  },[pageNumber,search])

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);

    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);


  const deleteUser =(id)=>{
    if(!isStaff){
      swal("Sorry! You are not an Admin!", {
        icon: "error",
      });
    }else{
    swal({
      title: `Are you sure?`,
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/auth/staff/${id}`;
        axios
          .delete(url)
          .then(() => {
            // showToast("Delele", "Data Deleted");
            // getProduct();
            setSearch('')
            apiFunction(
              `${BASE_URL}api/v1/auth/staff/?limit=${limit}&offset=${offset}`
            );
            swal("Poof! Your imaginary file has been deleted!", {
              icon: "success",
            });
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });

        
      } else {
        swal("Your imaginary file is safe!");
      }
    });}
  }
  const delete2 =()=>{
    swal("Sorry! Your you can not delete your own id!", {
      icon: "error",
    });
  }
  if (loading) {
    return <PmLoader/>
  }
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <div>
        <div className="upper">
          <div>
            <h5>{CommonData?.title}</h5>
            <p>{CommonData?.path}</p>
          </div>
          <div className="upper_user"
          aria-controls={open2 ? "long-menu" : undefined}
          aria-expanded={open2 ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick2}
          
          >
            <img src={userProfile?.image_url} alt="" />
          </div>
        </div>
        <>
        <Menu
          id="long-menu"
          className="pop_over3"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              width: "22ch",
              marginRight: "10ch",
              top: "89px !important",
              padding: "5px ",
            },
          }}
        >
          <MenuItem className="privacy my-2" onClick={()=>{
            history.push('/view-profile')
            handleClose2()
          }}>
            {" "}
            <UserCircle
              color="#1F5DA0"
              size={24}
              weight="bold"
              className="me-3"
            />
            View Profile
          </MenuItem>
          <MenuItem className="privacy my-2" onClick={()=>{
            history.push('/change-password')
            handleClose2()
          }}>
            {" "}
            <Lock color="#1F5DA0"
              size={24} weight="bold" className="me-3" />
            Change Password
          </MenuItem>
          <MenuItem className="privacy my-2" onClick={()=>{
            // history.push('/login')
            logout_func3(history)
            handleClose2()
          }}>
            {" "}
            <Power color="#1F5DA0"
              size={24} weight="bold" className="me-3" />
            Logout
          </MenuItem>
        </Menu>
      </>
        <div className="lower mt-4">
          <div className="input">
            <input value={search} onChange={(e)=>setSearch(e.target.value)} type="text" placeholder="Search ..." />
            <button>
              <MagnifyingGlass color="white" size={28} weight="bold" />
            </button>
          </div>

          <div>
            <button onClick={()=>history.push('/add-admin-and-staff')} className="d-flex add-user-btn">
              <img className="icon" src={add} alt="" />
              <span className="ms-2">{CommonData.btnData}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div class="table-responsive">
          <table class="table">
            <thead
              style={{
                backgroundColor: "#1f5da0",
                color: "white",
                fontSize: "18px",
              }}
            >
              <tr className="active_tr" style={{ height: "60px" }}>
                <th>ID</th>

                <th>FULL NAME</th>
                <th>PHONE NUMBER</th>
                <th>EMAIL</th>
                <th>IS ACTIVE</th>
                <th>IS STAFF</th>
                <th>IS SUPER ADMIN</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {
              staff.length > 0 && total_item ?
              (staff.map((data, index) => (
                <tr
                  key={index}
                  className={`active_tr ${
                    index % 2 === 0 ? "bg-even" : "bg-odd"
                  }`}
                >
                  <td>{data.id}</td>

                  <td>{data?.first_name} {data?.last_name}</td>
                  <td>{data.mobile}</td>
                  <td>{data.email}</td>
                  <td
                    className={`${data.is_active === "True" ? "true" : "false"}`}
                  >
                    {data.is_active === true ? 'True' : "false" }
                  </td>
                  <td
                    className={`${data.is_staff === "True" ? "true" : "false"}`}
                  >
                    {data.is_staff === true ? 'True' : "false"}
                  </td>
                  <td
                    className={`${data.is_superuser === "True" ? "true" : "false"}`}
                  >
                    {data.is_superuser === true ? 'True' : "false"}
                  </td>
                  <td>
                    <div className="d-flex justify-content-center gap-2">
                      <div
                        className="edit-del blue"
                        onClick={()=>handleShow(data)}
                        style={{
                          background: "#A0A0A0",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <RemoveRedEyeOutlined
                          style={{ color: "white" }}
                          className="icon"
                        />
                      </div>

                      <div
                        className="edit-del green"
                        style={{
                          background: "#418BFB",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                        }}
                        onClick={()=>history.push(`/edit-admin-and-staff/${data.id}`)}
                      >
                        <BiPencil color="white" className="icon" />
                      </div>
                      <div
                        className="edit-del red"
                        style={{
                          background: "#FE2C41",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                        }}
                        onClick={userProfile.email !== data?.email ? ()=> deleteUser(data.id) : delete2}
                      >
                        <TbTrash className="icon" style={{ color: "white" }} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))) : <Table404/>}
            </tbody>
          </table>
        </div>
      </div>
      {/* ==========paggination======= */}
      {
        total_item !== 0 && <PagginationForAll prevUrl={prevUrl} nextUrl={nextUrl} pageCount={pageCount} pageNumber={pageNumber} handlePaginationNext={handlePaginationNext} handlePaginationPrevious={handlePaginationPrevious} handleChangeCurrentPage={handleChangeCurrentPage} total_item={total_item} page_show_start={page_show_start} this_page_items_show={this_page_items_show} />
      }
      
      {/* ==========paggination======= */}
      <ViewModal singleUser={singleUser} show={show} setShow={setShow} handleClose={handleClose} />
    </div>
  );
};

export default AdminStaff;
