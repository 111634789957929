import axios from "axios";
import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import PageHeader from "../CommonDesgin/PageHeader";
import { logout_func3 } from "../Const/logoutCommon";
import { showToast } from "../Const/ToastHelper";
import { BASE_URL } from "../Const/Url";

const ChangePass = () => {
  const history=useHistory()
  const [oldpass, setOldpass] = useState("");
  const [oldEye, setOldeye] = useState(false);
  const [passEye, setPasseye] = useState(false);
  const [confPassEye, setConfpasseye] = useState(false);
  const [passValidation, setPassValidation] = useState("");
  const [finalPass, setFinalPass] = useState("");

  const CommonData = {
    title: "Change Password",
    path: "Dashboard/ Change Password",
    add: false,
    btnData: "Add New Admin",
    search: false,
  };
  const updatePass = () => {
    const url = `${BASE_URL}api/v1/auth/change/password/`;
    if (!oldpass) {
      showToast("error", "Inter Old Password !");
    } else if (passValidation?.length === 0 || passValidation?.length < 8) {
      showToast("error", "Inter Minimum 8 Digit Password !");
    } else if (passValidation !== finalPass) {
      showToast("error", " Password Did Not Match !");
    } else {
      const data = {
        old_password: oldpass,
        password: passValidation,
        confirm_password: finalPass,
      };
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.status) {
            console.log('pass',res.data)
            showToast("success", " Password updated login again !");
            logout_func3(history)
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }

          showToast("error", message);
        });
    }
  };
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <PageHeader CommonData={CommonData} />
      <div className="content_div bg-white row gap-2">
        <div className="row">
          <div className="col-12 global mt-3 input_container mt-3">
            <p>Old Password</p>
            <div
              className="number"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                type={oldEye ? "text" : "password"}
                style={{ border: "none", outline: "none" }}
                placeholder="Type Old password"
                onChange={(e) => setOldpass(e.target.value)}
              />
              <div onClick={() => setOldeye(!oldEye)}>
                {oldEye ? (
                  <AiOutlineEye
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 global mt-3 input_container mt-3">
            <p>New Password</p>
            <div
              className="number"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                type={passEye ? "text" : "password"}
                onChange={(e) => setPassValidation(e.target.value)}
                style={{ border: "none", outline: "none" }}
                placeholder="Type New password"
              />
              <div onClick={() => setPasseye(!passEye)}>
                {passEye ? (
                  <AiOutlineEye
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                )}
              </div>
            </div>
            {passValidation?.length > 0 && passValidation?.length < 8 && (
              <span>
                <RiErrorWarningLine /> Minimum 8 characters
              </span>
            )}
          </div>
          <div className="col-12 global mt-3 input_container mt-3">
            <p>Confirm Password</p>
            <div
              className="number"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                type={confPassEye ? "text" : "password"}
                style={{ border: "none", outline: "none" }}
                placeholder="Type New password"
                onChange={(e) => setFinalPass(e.target.value)}
              />
              <div onClick={() => setConfpasseye(!confPassEye)}>
                {confPassEye ? (
                  <AiOutlineEye
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                )}
              </div>
            </div>
            {finalPass?.length > 0 && finalPass !== passValidation && (
              <span>
                <RiErrorWarningLine />
                Password Didn't Match !
              </span>
            )}
          </div>

          <div className="col-12 global2 mt-3 d-flex justify-content-center">
            <button className="save" onClick={updatePass}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePass;
