import React from 'react'
import { PacmanLoader } from 'react-spinners'

const PmLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height:'100vh'}}>
      <PacmanLoader color="#418BFB" />
    </div>
  )
}

export default PmLoader