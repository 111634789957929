import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../CommonDesgin/PageHeader';
import PmLoader from '../../CommonDesgin/PmLoader';
import { logout_func3 } from '../../Const/logoutCommon';
import { showToast } from '../../Const/ToastHelper';
import { BASE_URL } from '../../Const/Url';

const ContactUs = () => {
    const CommonData = {
        title: "Contact Us",
        path: "Dashboard/ Administration/ Contact Us",
        add: false,
        btnData: "",
        search: false,
      };
      const [policy,setPolicy] = useState({})
      const [loading, setLoading] = useState(true);
      const history = useHistory();
      const [title,setTitle] = useState('')
      const [desc,setDesc] = useState('')
     
    
      const apiFunction = (url) => {
        axios
          .get(url)
          .then((res) => {
            setPolicy(res.data.data)
            setDesc(res.data.data?.desc)
            setTitle(res.data.data.title)
            setLoading(false);
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(err.request.response);
            if (
              message === "Invalid token." ||
              JSON.parse(err.request.response).code === 401
            ) {
              logout_func3(history);
            }
          });
      };
      useEffect(() => {
        // apiFunction(`${BASE_URL}v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}`)
        apiFunction(`${BASE_URL}api/v1/utility/admin/page/4/`);
      }, []);
    
      const updateData = ()=>{
        const url = `${BASE_URL}api/v1/utility/admin/page/4/`
        axios
          .patch(url, {
            title : title,
            desc:desc
          })
          .then((res) => {
            setLoading(true)
            if (res.data.status) {
              showToast("success", "Data Updated Successfully.");
              apiFunction(`${BASE_URL}api/v1/utility/admin/page/4/`);
              setLoading(false)
            }
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
            }
            console.log(err.request.response);
            showToast("error", message);
          });
    
      }
      if (loading) {
        return <PmLoader />;
      }
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
    <PageHeader CommonData={CommonData} />
    
    <div
      className="content_div bg-white  gap-2"
      style={{ minHeight: "75vh" }}
    >
      <div className="col-12 global mt-3">
        <p>Heading</p>
        <input value={title} onChange={(e)=>setTitle(e.target.value)} type="text" placeholder="Accepting the terms and conditions" />
      </div>
      <div className="col-12 global mt-3">
        <p> Description</p>
        <textarea value={desc} onChange={(e)=>setDesc(e.target.value)} placeholder="Write Description" />
      </div>
      <div className="col-12 global  mt-4">
        <button onClick={updateData}>Save Changes</button>
      </div>
    </div></div>
  )
}

export default ContactUs