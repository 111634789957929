import React, { useEffect, useState } from "react";
import PageHeader from "../../CommonDesgin/PageHeader";
import user1 from "../../../assets/img/1.svg";
import user2 from "../../../assets/img/2.svg";
import user3 from "../../../assets/img/3.svg";
import swal from "sweetalert";
import { CaretLeft, CaretRight, Lock, MagnifyingGlass, Power, UserCircle } from "phosphor-react";
import { TbTrash } from "react-icons/tb";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { BiPencil } from "react-icons/bi";
import { Checkbox, Menu, MenuItem } from "@material-ui/core";
import AddGroupModal from "./AddGroupModal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { isStaff, logout_func3 } from "../../Const/logoutCommon";
import { BASE_URL } from "../../Const/Url";
import PmLoader from "../../CommonDesgin/PmLoader";
import Table404 from "../../Const/Table404";
import PagginationForAll from "../../CommonDesgin/PagginationForAll";
import ViewGroup from "./ViewGroup";
import add from "../../../assets/img/add.svg";

const Group = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [singleUser,setSingleUser] = useState({})
  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => {
    setShow(true)
  };
  const handleClose2 = () => {
    setSingleUser({})
    setShow2(false)
  };
  const handleShow2 = (data) => {
    setSingleUser(data)
    setShow2(true)
  };
  const userProfile = JSON.parse(localStorage.getItem('userProfile'))
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl2(null);
  };
  const CommonData = {
    title: "Group List",
    path: "Dashboard/ Conversation/ Group ",
    add: true,
    btnData: "Add New Group",
    search: true
  };
  const tableData = [
    {
      id: 1,
      name: "Alex Robert",
      phone: "+8801363738735",
      email: "alexrobert32@gmail.com",
      img: user1,
    },
    {
      id: 2,
      name: "Ashraf Hossain",
      phone: "+8801763732735",
      email: "ashrafhossain@gmail.com",
      img: user2,
    },
    {
      id: 3,
      name: "Blake Morison",
      phone: "+8801963738935",
      email: "blakemorison@gmail.com",
      img: user3,
    },
    {
      id: 4,
      name: "Blake Morison",
      phone: "+8801963738935",
      email: "blakemorison@gmail.com",
      img: user1,
    },
  ];
  //clean up testing 
  const [search,setSearch] = useState('')
  const [activeUser,setActiveUser] = useState([])
  const [totalData, setTotalData] = useState(0);
  const [total_item, setTotal_item] = useState(0);
   const [loading, setLoading] = useState(true);
   const [pageNumber, setPageNumber] = useState(1);
   const [nextUrl, setNextUrl] = useState(null);
   const [prevUrl, setPrevUrl] = useState(null);
   const limit = 15;
   const item_limit = pageNumber * limit;
   const this_page_items_show = item_limit > total_item ? total_item : item_limit;
   const page_show_start = item_limit - limit + 1;
   const pageCount = Math.ceil(total_item / limit);
   const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
   const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
   const history = useHistory()
 
   const apiFunction = (url) => {
     axios
       .get(url)
       .then((res) => {
         setTotal_item(res.data?.data?.count);
         setTotalData(res.data?.data?.count);
         setActiveUser(res.data?.data?.results);
         setNextUrl(res.data?.data?.next);
         setPrevUrl(res.data?.data?.previous);
         setLoading(false);
       })
       .catch((err) => {
         const message = JSON.parse(err.request.response).message;
         console.log(err.request.response);
         if (
           message === "Invalid token." ||
           JSON.parse(err.request.response).code === 401
         ) {
           logout_func3(history)
         }
       });
   };
 
   useEffect(()=>{
     // apiFunction(`${BASE_URL}v1/auth/user/?is_active=true&limit=${limit}&offset=${offset}`)
     apiFunction(`${BASE_URL}api/v1/chat/admin/conversation/?convo_type=1&limit=${limit}&offset=${offset}&search=${search}`)
   },[pageNumber,search])
 
   const handlePaginationPrevious = () => {
     apiFunction(prevUrl);
 
     setPageNumber((prev) => {
       if (prev > 1) {
         return prev - 1;
       } else {
         return prev;
       }
     });
   };
   const handlePaginationNext = () => {
     // SetisLoading(true);
     apiFunction(nextUrl);
     setPageNumber((prev) => prev + 1);
   };
   const handleChangeCurrentPage = (val) => {
     setPageNumber(val);
   };
 
   useEffect(() => {
     if (pageCount !== 0) {
       if (pageCount < pageNumber) {
         setPageNumber(pageCount);
       }
     }
   }, [pageCount]);
   const deleteUser =(id)=>{
    if(!isStaff){
      swal("Sorry! You are not an Admin!", {
        icon: "error",
      });
    }else{
     swal({
       title: `Are you sure?`,
       text: "Once deleted, you will not be able to recover this imaginary file!",
       icon: "warning",
       buttons: true,
       dangerMode: true,
     })
     .then((willDelete) => {
       if (willDelete) {
         const url = `${BASE_URL}api/v1/chat/admin/conversation/${id}`;
         axios
           .delete(url)
           .then(() => {
             // showToast("Delele", "Data Deleted");
             // getProduct();
             setSearch('')
             apiFunction(`${BASE_URL}api/v1/chat/admin/conversation/?convo_type=1`)
             swal("Poof! Your imaginary file has been deleted!", {
               icon: "success",
             });
           })
           .catch((err) => {
             const message = JSON.parse(err.request.response).message;
             console.log(message);
           });
 
         
       } else {
         swal("Your imaginary file is safe!");
       }
     });}
   }
   if (loading) {
     return <PmLoader/>
   }
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <div>
      <div className="upper">
        <div>
          <h5>{CommonData?.title}</h5>
          <p>{CommonData?.path}</p>
        </div>
        <div
          className="upper_user"
          aria-controls={open2 ? "long-menu" : undefined}
          aria-expanded={open2 ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick2}
        >
          <img src={userProfile?.image_url} alt="" />
        </div>
      </div>
      <>
        <Menu
          id="long-menu"
          className="pop_over3"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose3}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              width: "22ch",
              marginRight: "10ch",
              top: "89px !important",
              padding: "5px ",
            },
          }}
        >
          <MenuItem className="privacy my-2" onClick={()=>{
            history.push('/view-profile')
            handleClose3()
          }}>
            {" "}
            <UserCircle
              color="#1F5DA0"
              size={24}
              weight="bold"
              className="me-3"
            />
            View Profile
          </MenuItem>
          <MenuItem className="privacy my-2" onClick={()=>{
            history.push('/change-password')
            handleClose3()
          }}>
            {" "}
            <Lock color="#1F5DA0"
              size={24} weight="bold" className="me-3" />
            Change Password
          </MenuItem>
          <MenuItem className="privacy my-2" onClick={()=>{
            logout_func3(history)
            handleClose2()
          }}>
            {" "}
            <Power color="#1F5DA0"
              size={24} weight="bold" className="me-3" />
            Logout
          </MenuItem>
        </Menu>
      </>
      <div className="lower mt-4">
        {CommonData.search === true && (
          <div className="input">
            <input value={search} onChange={(e)=>setSearch(e.target.value)} type="text" placeholder="Search ..." />
            <button>
              <MagnifyingGlass color="white" size={28} weight="bold" />
            </button>
          </div>
        )}

        {CommonData.add === true && (
          <div>
            <button className="d-flex add-user-btn" onClick={()=>history.push('/add_group')}>
              <img className="icon" src={add} alt="" />
              <span className="ms-2">{CommonData.btnData}</span>
            </button>
          </div>
        )}
      </div>
    </div>

      <div className="mt-4">
        <div class="table-responsive">
          <table class="table">
            <thead
              style={{
                backgroundColor: "#1f5da0",
                color: "white",
                fontSize: "18px",
              }}
            >
              <tr className="active_tr">
                <th>
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                    color="default"
                  />
                </th>
                <th>PROFILE PICTURE</th>
                <th>GROUP NAME</th>
                <th>GROUP MEMBER</th>

                <th>ACTION</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
            {activeUser.length > 0 && total_item ?
              (activeUser?.map((data, index) => (
                <tr
                  key={index}
                  className={`active_tr ${
                    index % 2 === 0 ? "bg-even" : "bg-odd"
                  }`}
                >
                  <td>
                    <Checkbox
                      sx={{
                        color: "gray",
                        "&.Mui-checked": {
                          color: "gray",
                        },
                      }}
                      color="default"
                    />
                  </td>
                  <td>
                    <img style={{width:'40px',height:'40px',borderRadius:'50%',objectFit:'cover'}}  src={data.group_image_url} alt="" />
                  </td>
                  <td>{data.group_name}</td>
                  <td>N/A</td>

                  <td>
                    <div className="d-flex justify-content-center gap-2">
                      <div
                        className="edit-del blue"
                        onClick={()=> handleShow2(data)}
                        style={{
                          background: "#A0A0A0",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <RemoveRedEyeOutlined
                          style={{ color: "white" }}
                          className="icon"
                        />
                      </div>

                      <div
                        className="edit-del green"
                        style={{
                          background: "#418BFB",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                        }}
                        onClick={()=> history.push(`/edit_group/${data.id}`)}
                      >
                        <BiPencil color="white" className="icon" />
                      </div>
                      <div
                        className="edit-del red"
                        style={{
                          background: "#FE2C41",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                        }}
                        onClick={()=>deleteUser(data.id)}
                      >
                        <TbTrash className="icon" style={{ color: "white" }} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))) : <Table404/>}
            </tbody>
          </table>
        </div>
      </div>
      {
        total_item !== 0 && <PagginationForAll prevUrl={prevUrl} nextUrl={nextUrl} pageCount={pageCount} pageNumber={pageNumber} handlePaginationNext={handlePaginationNext} handlePaginationPrevious={handlePaginationPrevious} handleChangeCurrentPage={handleChangeCurrentPage} total_item={total_item} page_show_start={page_show_start} this_page_items_show={this_page_items_show} />
      }
      
      {/* ==========paggination======= */}
      <AddGroupModal show={show} setShow={setShow} handleClose={handleClose} />
      <ViewGroup singleUser={singleUser} show={show2} setShow={setShow2} handleClose={handleClose2} />
    </div>
  );
};

export default Group;
