import React, { useState, useEffect } from "react";
import "./SideNav.css";
import addons from "../../../assets/icon/addonsIcon.svg";

import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UsersThree } from "phosphor-react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useLocation } from "react-router-dom";
import small_logo from "../../../assets/logo/small_logo.png";

import { GrNotification } from "react-icons/gr";
import Contact from "../../Svgs/Contact";
import Messege from "../../Svgs/Messege";
import Dashboard from "../../Svgs/Dashboard";
import Admin from "../../Svgs/Admin";
import Active from "../../Svgs/Active";
import Inactive from "../../Svgs/Inactive";
import whiteLogo from "../../../assets/logo/snowtexlogo.svg";

const SideNav = ({ menuCollapse }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentRoute = useHistory().location.pathname.toLowerCase();
  const [menu, setMenu] = useState(menuCollapse);
  const [activeNav, setActiveNave] = useState(location.pathname);
  const [visible, setVisible] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  useEffect(() => {
    setMenu(menuCollapse);
  }, [menuCollapse]);

  useEffect(() => {
    setActiveNave(location.pathname);
  }, [location.pathname]);

  const shootid = localStorage.getItem("shootid");

  // ----------------logout-------------
  const handleLogOut = () => {
    let response = {
      status: false,
      message: "",
      isLoading: true,
    };

    try {
      localStorage.removeItem("is_logged_in");
      localStorage.removeItem("access_token");
      localStorage.removeItem("userData");

      // toast.success('Logged out successfully !');
      if (typeof window !== "undefined") {
        window.loction.href = "/";
      }
    } catch (error) {
      response.message = "Something Went Wrong !";
      // toast.error(error);
    }
    response.isLoading = false;
  };

  return (
    <>
      <div className="sidebar">
        <ProSidebar collapsed={menu}>
          <SidebarHeader>
            <div className="logo ">
              {menu ? (
                <img width={40} src={whiteLogo} alt="" />
              ) : (
                <img width={150} className="img-fluid" src={whiteLogo} alt="" />
              )}
            </div>
          </SidebarHeader>
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <Menu iconShape="square">
                <MenuItem
                  active={
                    activeNav === "/" || activeNav === `/preview` ? true : false
                  }
                  onClick={() => setActiveNave("/", `/`)}
                  icon={<Dashboard />}
                >
                  {" "}
                  <NavLink
                    style={{ fontSize: "20px",}}
                    exact
                    to="/"
                  >
                    <span style={{ fontSize: "20px", color: "gray" }}>
                      Admin Dashboard
                    </span>
                  </NavLink>
                </MenuItem>

                {/* ------------------------all user---------------------- */}

                {/* ------------------------Package---------------------- */}

                <SubMenu
                  // onClick={() => setVisible(!visible)}
                  active={activeNav === `/active-user` ? true : false}
                  icon={<Contact />}
                  title="User"
                  // {visible === true ? open : open ={false}}
                  // open={visible}
                >
                  <MenuItem
                    active={activeNav === `/active-user` ? true : false}
                    onClick={() => setActiveNave("/active-user")}
                    icon={
                      activeNav === `/active-user` ? <Active /> : <Inactive />
                    }
                  >
                    <NavLink to="/active-user">Active User</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/inactive-user" ? true : false}
                    onClick={() => setActiveNave("/inactive-user")}
                    icon={
                      activeNav === `/inactive-user` ? <Active /> : <Inactive />
                    }
                  >
                    <NavLink to="/inactive-user">Inactive User</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/approve-user" ? true : false}
                    onClick={() => setActiveNave("/approve-user")}
                    icon={
                      activeNav === `/approve-user` ? <Active /> : <Inactive />
                    }
                  >
                    <NavLink to="/approve-user">Approve User</NavLink>
                  </MenuItem>
                </SubMenu>
                {/* comented menu */}

                {/* comented menu */}
                <SubMenu
                  // onClick={() => setVisible(!visible)}
                  active={activeNav === `/personal` ? true : false}
                  icon={<Messege />}
                  title="Conversation"
                  // {visible === true ? open : open ={false}}
                  // open={visible}
                >
                  <MenuItem
                    active={activeNav === `/personal` ? true : false}
                    onClick={() => setActiveNave("/personal")}
                    icon={activeNav === `/personal` ? <Active /> : <Inactive />}
                  >
                    <NavLink to="/personal">Personal</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/group" ? true : false}
                    onClick={() => setActiveNave("/group")}
                    icon={activeNav === `/group` ? <Active /> : <Inactive />}
                  >
                    <NavLink to="/group">Group</NavLink>
                  </MenuItem>
                </SubMenu>
                {/* =========Addons====== */}
                <SubMenu
                  // onClick={() => setVisible(!visible)}
                  active={activeNav === `/global-settings` ? true : false}
                  icon={<Admin />}
                  title="Administration"
                  // {visible === true ? open : open ={false}}
                  // open={visible}
                >
                  <MenuItem
                    active={activeNav === `/global-settings` ? true : false}
                    onClick={() => setActiveNave("/global-settings")}
                    icon={
                      activeNav === `/global-settings` ? (
                        <Active />
                      ) : (
                        <Inactive />
                      )
                    }
                  >
                    <NavLink to="/global-settings">Global Settings</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/admin-and-staff" ? true : false}
                    onClick={() => setActiveNave("/admin-and-staff")}
                    icon={
                      activeNav === `/admin-and-staff` ? (
                        <Active />
                      ) : (
                        <Inactive />
                      )
                    }
                  >
                    <NavLink to="/admin-and-staff">Admin and Staff</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={
                      activeNav === "/terms-and-conditions" ? true : false
                    }
                    onClick={() => setActiveNave("/terms-and-conditions")}
                    icon={
                      activeNav === `/terms-and-conditions` ? (
                        <Active />
                      ) : (
                        <Inactive />
                      )
                    }
                  >
                    <NavLink to="/terms-and-conditions">
                      Terms and Conditions
                    </NavLink>
                  </MenuItem>
                  {/* <MenuItem
                    active={activeNav === "/privacy-policy" ? true : false}
                    onClick={() => setActiveNave("/privacy-policy")}
                    icon={
                      activeNav === `/privacy-policy` ? (
                        <Active />
                      ) : (
                        <Inactive />
                      )
                    }
                  >
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                  </MenuItem> */}
                  <MenuItem
                    active={activeNav === "/about-us" ? true : false}
                    onClick={() => setActiveNave("/about-us")}
                    icon={activeNav === `/about-us` ? <Active /> : <Inactive />}
                  >
                    <NavLink to="/about-us">About Us</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/contact-us" ? true : false}
                    onClick={() => setActiveNave("/contact-us")}
                    icon={activeNav === `/contact-us` ? <Active /> : <Inactive />}
                  >
                    <NavLink to="/contact-us">Contact Us</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/help-center" ? true : false}
                    onClick={() => setActiveNave("/help-center")}
                    icon={activeNav === `/help-center` ? <Active /> : <Inactive />}
                  >
                    <NavLink to="/help-center">Help Center</NavLink>
                  </MenuItem>
                </SubMenu>
                {/* -------------------------------------------Ticket---------------------------- */}
                {/* <SubMenu
                  active={activeNav === `/allitems` ? true : false}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1567_15719)">
                        <path
                          d="M19.5759 6.75H4.42586C4.24131 6.75087 4.06339 6.81891 3.92536 6.94141C3.78733 7.06391 3.69864 7.23249 3.67586 7.41562L2.34461 19.4156C2.33277 19.5202 2.34306 19.6261 2.37482 19.7264C2.40658 19.8267 2.45909 19.9192 2.52894 19.9979C2.59879 20.0766 2.68442 20.1397 2.78026 20.1831C2.8761 20.2266 2.98001 20.2494 3.08523 20.25H20.9165C21.0217 20.2494 21.1256 20.2266 21.2215 20.1831C21.3173 20.1397 21.4029 20.0766 21.4728 19.9979C21.5426 19.9192 21.5951 19.8267 21.6269 19.7264C21.6587 19.6261 21.6689 19.5202 21.6571 19.4156L20.3259 7.41562C20.3031 7.23249 20.2144 7.06391 20.0764 6.94141C19.9383 6.81891 19.7604 6.75087 19.5759 6.75V6.75Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.25 9.75V6.75C8.25 5.75544 8.64509 4.80161 9.34835 4.09835C10.0516 3.39509 11.0054 3 12 3C12.9946 3 13.9484 3.39509 14.6517 4.09835C15.3549 4.80161 15.75 5.75544 15.75 6.75V9.75"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1567_15719">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                  title="Items"
                >
                  <MenuItem
                    active={activeNav === `/allitems` ? true : false}
                    onClick={() => setActiveNave("/allitems")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/allitems">All Item</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/additem" ? true : false}
                    onClick={() => setActiveNave("/additem")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/additem">Add Item</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/addattribute" ? true : false}
                    onClick={() => setActiveNave("/addattribute")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/addattribute">Add Attribute</NavLink>
                  </MenuItem>
                </SubMenu> */}
                {/* =============ticket====== */}
                {/* <SubMenu
                  active={activeNav === `/alluser` ? true : false}
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1315_639)">
                        <path
                          d="M19.7167 3.36572L2.24166 8.28759C2.0929 8.32826 1.9603 8.4138 1.86191 8.53256C1.76353 8.65131 1.70414 8.79751 1.69184 8.95123C1.67954 9.10496 1.71493 9.25874 1.79319 9.39162C1.87144 9.52451 1.98876 9.63004 2.12916 9.69384L10.1542 13.4907C10.3114 13.5635 10.4376 13.6898 10.5104 13.847L14.3073 21.872C14.3711 22.0124 14.4766 22.1297 14.6095 22.2079C14.7424 22.2862 14.8962 22.3216 15.0499 22.3093C15.2036 22.297 15.3498 22.2376 15.4686 22.1392C15.5873 22.0408 15.6729 21.9082 15.7135 21.7595L20.6354 4.28447C20.6727 4.15695 20.675 4.02174 20.6421 3.89302C20.6092 3.7643 20.5422 3.64681 20.4483 3.55286C20.3543 3.45891 20.2368 3.39197 20.1081 3.35904C19.9794 3.32611 19.8442 3.32842 19.7167 3.36572V3.36572Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.3965 13.6027L14.634 9.36523"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1315_639">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                  title="Ticket"
                >
                  <MenuItem
                    active={activeNav === `/allticket` ? true : false}
                    onClick={() => setActiveNave("/allticket")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/allticket">All Ticket</NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === "/addticket" ? true : false}
                    onClick={() => setActiveNave("/addticket")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/addticket">Add Ticket</NavLink>
                  </MenuItem>
                </SubMenu> */}
                {/* ===========ticket====== */}

                {/* -------------------report--------------------------------------- */}
                {/* <SubMenu
                  active={activeNav === `/customerReport` ? true : false}
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1315_628)">
                        <path
                          d="M21 19.5H3V4.5"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.5 6L12 13.5L9 10.5L3 16.5"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.5 9.75V6H15.75"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1315_628">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                  title="Report"
                >
                  <MenuItem
                    active={activeNav === `/customerReport` ? true : false}
                    onClick={() => setActiveNave("/customerReport")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/customerReport">Customer Report </NavLink>
                  </MenuItem>
                  <MenuItem
                    active={activeNav === `/AddonsReport` ? true : false}
                    onClick={() => setActiveNave("/AddonsReport")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/AddonsReport">Addons Report </NavLink>
                  </MenuItem>
                  

                  <MenuItem
                    active={activeNav === "/ticketReport" ? true : false}
                    onClick={() => setActiveNave("/ticketReport")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/ticketReport">Ticket Report </NavLink>
                  </MenuItem>

                  <MenuItem
                    active={activeNav === "/ItemReport" ? true : false}
                    onClick={() => setActiveNave("/ItemReport")}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.74408 15.5893C7.41864 15.2639 7.41864 14.7362 7.74408 14.4108L12.1548 10L7.74408 5.58929C7.41864 5.26387 7.41864 4.73621 7.74408 4.41079C8.06952 4.08537 8.59717 4.08537 8.92258 4.41079L13.9226 9.41079C14.248 9.73621 14.248 10.2639 13.9226 10.5893L8.92258 15.5893C8.59717 15.9147 8.06952 15.9147 7.74408 15.5893Z"
                          fill="#212121"
                        />
                      </svg>
                    }
                  >
                    <NavLink to="/ItemReport">Item Report </NavLink>
                  </MenuItem>
                  
                </SubMenu> */}

                {/* ==notification======== */}
                {/* <MenuItem
                  active={
                    activeNav === "/notification" || activeNav === `/preview`
                      ? true
                      : false
                  }
                  onClick={() => setActiveNave("/notification", `/preview`)}
                  icon={
                    <GrNotification
                      style={{
                        height: "20px",
                        width: "20px",
                        fontWeight: "500",
                      }}
                    />
                  }
                >
                  {" "}
                  <NavLink exact to="/notification">
                    Notification
                  </NavLink>
                </MenuItem> */}
              </Menu>
            </div>
          </div>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideNav;
