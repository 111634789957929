import {
  Camera,
  Envelope,
  MagnifyingGlass,
  Phone,
  Smiley,
  X,
} from "phosphor-react";
import React from "react";
import { Modal } from "react-bootstrap";
import viewUser from "../../../assets/img/viewuser.svg";
import "../Convo.css";
import user1 from "../../../assets/img/1.svg";
import user2 from "../../../assets/img/2.svg";

const AddGroupModal = ({ show, setShow, handleClose }) => {
  return (
    <Modal size="lg" show={show} centered onHide={handleClose}>
      <div className="d-flex justify-content-end ">
        <button
          onClick={() => setShow(false)}
          className="modal-btn"
          style={{
            border: "1px solid #263238",
            outline: "none",
            marginTop: "-15px",
            marginRight: "-15px",
          }}
        >
          <X size={26} color={"#263238"} weight="thin" />
        </button>
      </div>
      <Modal.Body>
        <div className="d-flex justify-content-center ">
          {/* <img src={viewUser} alt="" /> */}
          <div className="add_img">
            <Camera color="white" size={42} weight="light" />
            <input type="file" accept="image/png, image/gif, image/jpeg" />
          </div>
        </div>
        <h4 className="mt-4" style={{ textAlign: "center", color: "#1F5DA0" }}>
          Add Group Photo
        </h4>
        <div className="group_data" style={{ marginTop: "40px" }}>
          <div className="input_field">
            <input type="text" placeholder="Type Hroup Name Here.." />
            <Smiley className="smily" size={29} />
          </div>
        </div>
        <div className="group_data" style={{ marginTop: "40px" }}>
          <div className="input_field">
            <input type="text" placeholder="Add User" />
            <MagnifyingGlass className="smily" size={29} />
          </div>
        </div>
        <div className="group_data">
          <div className="users p-2 gap-1 row">
            <div className="col-12 col-md-4 d-flex  group_member">
              <img width={35} src={user1} alt="" />
              <span className="mx-2">Robert Lio</span>
              <X size={18} className=" gp_icon" color={"#1D1D1D"} />
            </div>
            <div className="col-12 col-md-4  group_member">
              <img width={35} src={user2} alt="" />
              <span className="mx-2">Robert Lio</span>
              <X size={18} className=" gp_icon" color={"#1D1D1D"} />
            </div>
          </div>
        </div>
        <div className="group_data">
          <span
            className="selected_members mt-4"
            style={{ textAlign: "center" }}
          >
            Selected Members : 2{" "}
          </span>
        </div>{" "}
        <div className="group_data mt-4">
            <button className="save_group">Save</button>
        </div>
        <div style={{ marginTop: "40px" }}></div>
      </Modal.Body>
    </Modal>
  );
};

export default AddGroupModal;
