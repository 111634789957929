import { RemoveRedEyeOutlined } from "@material-ui/icons";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { RiEdit2Line } from "react-icons/ri";
import { TbTrash } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../Const/Loader";
import { showToast } from "../Const/ToastHelper";
import { BASE_URL } from "../Const/Url";

const AddonsList = ({query, date, leadsList}) => {
  const history = useHistory();
  const addonsData = [
    {
      id: 1,
      addonsName: "Dominos Pizza",
      quantity: 10,
      aditionalPrice: "£659,490",
      status: "active",StatusBtnColour: "#219653",
    },
    {
      id: 2,
      addonsName: "Cold Drinks",
      quantity: 20,
      aditionalPrice: "£59,490",
      status: "active",StatusBtnColour: "#219653",
    },
    {
      id: 3,
      addonsName: "Large Burger",
      quantity: 15,
      aditionalPrice: "£20,490",
      status: "disable",StatusBtnColour: "#219653",
    },
    {
      id: 4,
      addonsName: "Soft Drinks",
      quantity: 60,
      aditionalPrice: "£659.00",
      status: "active",StatusBtnColour: "#219653",
    },
    {
      id: 5,
      addonsName: "Biriani",
      quantity: 40,
      aditionalPrice: "£459,490",
      status: "disable",StatusBtnColour: "#219653",
    },
    {
      id: 6,
      addonsName: "Fried Rice",
      quantity: 40,
      aditionalPrice: "£459,490",
      status: "disable",StatusBtnColour: "#219653",
    },
  ];
  const [adonsList,setAtonsList] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const getAddons = () => {
    const url = `${BASE_URL}api/v1/inventory/addons/`;

    axios
      .get(url)
      .then((res) => {
        const result = res.data.data.results;
        console.log(result);
        setAtonsList(result);
        setIsLoading(false);
      })
      .catch((err) => {
        // const message = JSON.parse(err.request.response).message;
        console.log(err.request?.response);
      });
  };
  useEffect(() => {
    setAtonsList(leadsList)
    if(adonsList){
      setIsLoading(false)
    }
  }, [leadsList]);

  const deleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      const url = `${BASE_URL}api/v1/inventory/addons/${id}/`;
        axios
          .delete(url)
          .then(() => {
            showToast("Delele", "Data Deleted");
            setAtonsList(adonsList.filter(user => user.id !== id))
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });
      if (willDelete) {
        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  
  const keys = ["name","quantity","price"];
  

  const getData = adonsList?.filter((p) =>
      keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
    );

    if (isLoading) {
      return <Loader />;
    }
  return (
    <div className="categorey-parent" style={{ height: "100%" }}>
      <div className="cotagorey-head mb-4">
        <h3>Item Table</h3>
      </div>
      {/* table */}
      <div style={{ overflowX: "auto" }} className="table-responsive">
        <div>
          <table className="table_my">
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px" }}
            >
              <tr className="">
                <th
                  // className="ms-4"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopLeftRadius: "4px ",
                    textAlign: "center",
                    // margin: "0px 20px",
                  }}
                >
                  <p className="ms-3 ">Addons Name</p>
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Additional Price
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Status
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {getData.length > 0 ? getData.map((addons) => (
                <tr className="table-row " key={addons.id}>
                  <td style={{ textAlign: "center" }}>{addons.name}</td>
                  <td style={{ textAlign: "center" }}>{addons.quantity}</td>
                  <td style={{ textAlign: "center" }}>£{addons.price}</td>
                  <td className="" style={{ textAlign: "center" }}>
                  <button
                        className="status"
                        style={{
                          color: `${addons?.status === true ? '#219653' :'white'}`,
                          background: `${addons?.status === false ? '#FF6263' :''}`,
                          textAlign: "center",
                        }}
                      >
                        {addons?.status === true ? 'Active' : 'Disable'}
                      </button>
                </td>
                <td>
                  <div className="d-flex justify-content-center gap-2">
                    {/* <Link to='user-view'> */}
                    
                    {/* </Link> */}
                    <div className="edit-del green" >
                      <RiEdit2Line className="icon" onClick={()=> history.push(`/editaddons/${addons.slug}`)} />
                    </div>
                    <div className="edit-del red"  onClick={() => deleteUser(addons.slug)}>
                      <TbTrash className="icon" />

                      {/* <DeleteOutline className="icon" /> */}
                    </div>
                  </div>
                </td>
                </tr>
              )) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddonsList;
