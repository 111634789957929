import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import { handleInputs } from "../Const/HandleInputs";
import { showToast } from "../Const/ToastHelper";
import { BASE_URL } from "../Const/Url";

const AddAddons = () => {
  const history = useHistory();
  const [parentId, setParentId] = useState(0);
  const [categoryList, setcategoryList] = useState([]);
  const [catSwitch, setCatSwitch] = useState(true);
  const [addonsPost, setAddonsPost] = useState({
    name: "",
    price: "",
    quantity: "",
  });
  const toggleSwitch = () => {
    setCatSwitch((current) => !current);
  };
 
  const getCategory = () => {
    const url = `${BASE_URL}api/v1/inventory/category/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        setcategoryList(options);
        setParentId(result[0].id);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    getCategory();
  }, []);

  const postAddons = () => {
    const url = `${BASE_URL}api/v1/inventory/addons/`;
    const data = new FormData();
    data.append("name", addonsPost["name"]);
    data.append("price", addonsPost["price"]);
    data.append("quantity", addonsPost["quantity"]);
    data.append("parent_category", parentId);
    data.append("status", catSwitch);
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Category added.");
          history.push("/alladdons");
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        console.log(errorMsg);
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  return (
    <div className="container-fluid">
      <div className="row mx-3">
        <div className="col-md-12">
          <div className="personal_info mt-5 p-4">
            <div className="personal_info_top mb-4">
              <h3>Addons Information</h3>
              <p>Please check your personal details here</p>
            </div>
            <hr />
            <div className="persional_info_form">
              <div className="row my-4">
                <div className="col-md-12">
                  <div className="row">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-2 col-form-label align-self-center"
                    >
                      Addons Name <span className="require_mark">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Antu"
                        name="name"
                        value={addonsPost["name"]}
                        onChange={(e) => handleInputs(e, setAddonsPost)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="row my-4">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-2 col-form-label align-self-center"
                >
                  Parent Category <span className="require_mark">*</span>
                </label>
                <div className="col-sm-10 " style={{ position: "relative" }}>
                  <select
                    id="inputState"
                    className="form-control"
                    onChange={(e) => setParentId(e.target.value)}
                  >
                    {categoryList.map((category) => (
                      <option key={category.value} value={category.value}>
                        {category.label}
                      </option>
                    ))}
                  </select>
                  <div className="arrow-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1548_1885)">
                        <path
                          d="M16.8281 7.25782C16.7803 7.14406 16.6998 7.04701 16.597 6.97889C16.4941 6.91076 16.3733 6.87462 16.2499 6.875H3.74994C3.62655 6.87462 3.5058 6.91076 3.40292 6.97889C3.30004 7.04701 3.21963 7.14406 3.17181 7.25782C3.12679 7.37326 3.11536 7.49909 3.13887 7.62076C3.16237 7.74242 3.21984 7.85495 3.30463 7.94532L9.55463 14.1953C9.67409 14.3108 9.83376 14.3754 9.99994 14.3754C10.1661 14.3754 10.3258 14.3108 10.4453 14.1953L16.6953 7.94532C16.78 7.85495 16.8375 7.74242 16.861 7.62076C16.8845 7.49909 16.8731 7.37326 16.8281 7.25782V7.25782Z"
                          fill="#333333"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1548_1885">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>

              <hr />

              <div className="row my-4">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-2 col-form-label align-self-center"
                >
                  Aditional Price <span className="require_mark">*</span>
                </label>
                <div className="col-sm-10">
                  <input
                    type="number"
                    className="form-control"
                    id="inputPassword"
                    placeholder="£459,490"
                    name="price"
                    value={addonsPost["price"]}
                    onChange={(e) => handleInputs(e, setAddonsPost)}
                  />
                </div>
              </div>
              <hr />
              <div className="row my-4">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-2 col-form-label align-self-center"
                >
                  Quantity <span className="require_mark">*</span>
                </label>
                <div className="col-sm-10">
                  <input
                    type="number"
                    className="form-control"
                    id="inputPassword"
                    placeholder="490"
                    name="quantity"
                    value={addonsPost["quantity"]}
                    onChange={(e) => handleInputs(e, setAddonsPost)}
                  />
                </div>
              </div>
              <hr />
              <div class="col-12 col-lg-5">
                <h5 style={{ marginTtop: "30px" }}>Status</h5>
                <p style={{ color: "rgb(142, 142, 147)" }}>
                  ( If the Category Option is available )
                </p>
                <div class="row mr-4 mt-3">
                  <div
                    class=" d-flex align-items-center justify-content-between col-12 py-2"
                    style={{
                      backgroundColor: "rgb(242, 242, 247)",
                      borderRadius: "8px",
                    }}
                  >
                    <div class="d-flex w-100  align-items-center">
                      <div
                        class="d-flex py-1 justify-content-center align-items-center"
                        style={{ width: "32px", borderRadius: "3px" }}
                      >
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="32" height="32" rx="4" fill="#1597E5" />
                          <path
                            d="M25.2565 14.962C25.7305 15.582 25.7305 16.419 25.2565 17.038C23.7635 18.987 20.1815 23 15.9995 23C11.8175 23 8.23552 18.987 6.74252 17.038C6.51191 16.7411 6.38672 16.3759 6.38672 16C6.38672 15.6241 6.51191 15.2589 6.74252 14.962C8.23552 13.013 11.8175 9 15.9995 9C20.1815 9 23.7635 13.013 25.2565 14.962V14.962Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <span class="mx-3" Style={{ color: "#1597E5" }}>
                        {catSwitch ? "ON" : "OFF"}
                      </span>
                    </div>
                    <div class="form-check form-switch ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={catSwitch}
                        role="switch"
                        id="flexSwitchCheckDefault"
                        width="40"
                        height="40"
                        onClick={toggleSwitch}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="save_and_next_btn mt-5 text-end pt-5">
                <button
                  onClick={() => history.push(`/alladdons`)}
                  className="me-3 bg-light "
                  style={{ color: "black" }}
                >
                  Cancel
                </button>
                <button
                onClick={postAddons}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddons;
