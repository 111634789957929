import { Envelope, Phone, X } from 'phosphor-react'
import React from 'react'
import { Modal } from 'react-bootstrap'

const ViewGroup = ({show,setShow,handleClose,singleUser}) => {
  return (
    <Modal size="lg" show={show} centered onHide={handleClose}>
    <div className="d-flex justify-content-end ">
      <button
        onClick={handleClose}
        className="modal-btn"
        style={{
          border: "1px solid #263238",
          outline: "none",
          marginTop: "-15px",
          marginRight: "-15px",
        }}
      >
        <X size={26} color={'#263238'} weight="thin" />
      </button>
    </div>
    <Modal.Body>
      <div className="d-flex justify-content-center ">
        <img style={{width:'100px',height:'100px',borderRadius:'50%',objectFit:'cover'}} src={singleUser?.group_image_url} alt="" />
      </div>
      <h4 className="mt-4" style={{ textAlign: "center" }}>
        {/* {singleUser?.first_name} {singleUser?.last_name} */} {singleUser?.group_name}
      </h4>

      <div className="modal_data d-none">
        <div className="d-flex my-2 align-items-center justify-content-center">
          <Phone color="#1F5DA0" className="me-2" size={28} weight="bold" />
          {/* <span>{singleUser?.mobile}</span> */}
        </div>
      </div>
      <div className="modal_data d-none">
        <div className="d-flex my-2 align-items-center justify-content-center">
          <Envelope color="#1F5DA0" className="me-2" size={28} weight="bold" />
          {/* <span>{singleUser?.email}</span> */}
        </div>
      </div>
      <div className="mb-4">

      </div>
    </Modal.Body>
  </Modal>
  )
}

export default ViewGroup