import React from "react";

const Inactive = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      style={{transition:'1s all ease'}}
    >
      <rect
        width="8"
        height="8"
        rx="4"
        transform="matrix(1 0 0 -1 0 8)"
        fill="#1F5DA0"
        fill-opacity="0.6"
      />
    </svg>
  );
};

export default Inactive;
