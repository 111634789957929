import React from "react";
import verify from "../../../../assets/img/Forget.svg";
import tic from "../../../../assets/img/tic.svg";

import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
// import "../Authentication.css";

const Verify = ({ setHideToolbar }) => {
  const history = useHistory();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeOtp, setActiveOtp] = useState(0);
  const [finalOtp, setFinalOtp] = useState(null);
  const inputRef = useRef();
  const handleOtpChange = ({ target }, index) => {
    const { value } = target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    // const val = value.substring(value.length - 1)
    // console.log(val)
    if (!value) setActiveOtp(index - 1);
    else setActiveOtp(index + 1);
    setOtp(newOtp);
    setFinalOtp(newOtp.join());
  };
  console.log("finalotp", finalOtp);
  console.log(
    "otpData",
    `${otp[0]}${otp[1]}${otp[2]}${otp[3]}${otp[4]}${otp[5]}`
  );
  const handleKeyDown = ({ key }, index) => {
    if (key === "Backspace") setActiveOtp(index - 1);
    console.log(key);
  };
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtp]);
  useEffect(() => {
    if (localStorage.getItem("is_logged_in")) {
      history.push("/dashboard");
    }
    setHideToolbar(true);
    return () => {
      setHideToolbar(false);
    };
  }, []);
  const getBackDashboard = () => {
    setHideToolbar(false);
    history.push("/dashboard");
  };
  return (
    <div className="login_main">
      <div>
        <div className="logo_image" style={{ position: "relative" }}>
          <img className="img-fluid" src={verify} alt="" />
          <img className="img-fluid tic" src={tic} alt="" />
        </div>
        <h3 className="login_text">Verify Code</h3>
        <span className="verify-text">
          Please enter your 6 digit code sent to your phone
        </span>
        <div className="code_div d-flex justify-content-between mt-3">
          {otp.map((_, index) => (
            <div key={index} className="mx-1">
              <input
                value={otp[index]}
                ref={index === activeOtp ? inputRef : null}
                type="number"
                style={{ textAlign: "center" }}
                onChange={(e) => handleOtpChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            </div>
          ))}
        </div>
        <p className="last_text mt-3 text-center">
          Don’t receive code?{" "}
          <span
            className="send_again"
            //    onClick={resendOtp}
          >
            Sent again
          </span>
        </p>
        <div className="input_container" style={{ marginTop: "37px" }}>
          <button
            className="w-100  btn-outline-none"
            onClick={getBackDashboard}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default Verify;
