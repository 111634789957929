import * as Types from "../types/Types";
const PaginationInitial = {
  name: "",
  limit: {
    lead: 10,
    Category: 10,
    priceQuote: 10,
    Addons: 10,
    Ticket: 10,
    Item: 10,
  },
  IsLoading: {
    lead: true,
    Category: true,
    priceQuote: true,
    Addons: true,
    Ticket: true,
    Item: true,
  },
  data: {
    lead: [],
    Category: [],
    priceQuote: [],
    Addons: [],
    Ticket: [],
    Item: [],
  },
  error: {
    lead: null,
    Category: null,
    priceQuote: null,
    Addons: null,
    Ticket: null,
    Item: null,
  },
  isLoadLeads: {
    lead: false,
    Category: false,
    priceQuote: false,
    Addons: false,
    Ticket: false,
    Item: false,
  },
  url: {
    lead: "",
    Category: "",
    priceQuote: "",
    Addons: "",
    Ticket: "",
    Item: '',
  },
  pageNumber: {
    lead: 1,
    Category: 1,
    priceQuote: 1,
    Addons: 1,
    Item: 1,
  },
};

export const PaginationReducer = (state = PaginationInitial, action) => {
  switch (action.type) {
    case Types.PAGINATION_NAME:
      return {
        ...state,
        name: action.payload,
      };

    case Types.PAGINATION_lIMIT:
      return {
        ...state,
        limit: {
          ...state.limit,
          [state.name]: action.payload,
        },
      };

    case Types.IS_LOAD_LEADS:
      return {
        ...state,
        isLoadLeads: {
          ...state.isLoadLeads,
          [state.name]: action.payload,
        },
      };

    case Types.PAGINATION_LOADING:
      return {
        ...state,
        IsLoading: {
          ...state.IsLoading,
          [state.name]: action.payload,
        },
      };
    case Types.PAGINATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [state.name]: action.payload,
        },
      };
    case Types.PAGINATION_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [state.name]: action.payload,
        },
      };
    case Types.PAGINATION_URL:
      return {
        ...state,
        url: {
          ...state.url,
          [state.name]: action.payload,
        },
      };
    case Types.PAGINATION_NEXT:
      return {
        ...state,
        pageNumber: {
          ...state.pageNumber,
          [state.name]: state.pageNumber[state.name] + 1,
        },
      };
    case Types.PAGINATION_PREVIOUS:
      return {
        ...state,
        pageNumber: {
          ...state.pageNumber,
          [state.name]:
            state.pageNumber[state.name] > 1
              ? state.pageNumber[state.name] - 1
              : state.pageNumber[state.name],
        },
      };

    case Types.PAGINATION_CHANGE_CURRENT:
      return {
        ...state,
        pageNumber: {
          ...state.pageNumber,
          [state.name]: action.payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
