import React, { useEffect, useState } from "react";
import PageHeader from "../../CommonDesgin/PageHeader";
import select from "../../../assets/img/select.svg";
import add from "../../../assets/img/add.svg";
import { X } from "phosphor-react";
import { Checkbox } from "@material-ui/core";
import { BASE_URL } from "../../Const/Url";
import { RiErrorWarningLine } from "react-icons/ri";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { showToast } from "../../Const/ToastHelper";
import { useHistory } from "react-router-dom";
import axios from "axios";

const AddAdminStaff = () => {
  
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [countryCode, setCountryCode] = useState([
    {
      id: 1,
      phone_code: "+110",
    },
    {
      id: 2,
      phone_code: "+880",
    },
    {
      id: 3,
      phone_code: "+991",
    },
    {
      id: 4,
      phone_code: "+440",
    },
    {
      id: 5,
      phone_code: "+881",
    },
  ]);
  const [image, setImage] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const [preview, setPreview] = useState(null);
  const onSelectFile = (event) => {
    setUploaded(event.target.files[0]);
  };
  const removeImg = () => {
    setUploaded(null);
    setPreview(null);
  };
  const CommonData = {
    title: "Admin and Staff List",
    path: "Dashboard/ Administration/ Admin and Staff/ Admin and Staff List",
    add: false,
    btnData: "Add New Admin",
    search: false,
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [finalPass, setFinalPass] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [userCountryCode, setUserCountryCode] = useState("+880");
  const [numberValidation, setNumberValidation] = useState(null);
  const [passValidation, setPassValidation] = useState("");
  const [eye, setEye] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [staff, setStaff] = useState(false);
  const [superadmin, setSuperadmin] = useState(false);
  useEffect(() => {
    const url = `${BASE_URL}api/v1/auth/country/`;
    const fetchQuote = async () => {
      const res = await fetch(url);
      const data = await res.json();
      setCountryCode(data.data.results);
      setLoading(false);
    };

    fetchQuote();
  }, []);
  const changeEye = () => {
    setEye((prev) => !prev);
  };
  const phoneNumber = `${userCountryCode}${numberValidation}`;
  // console.log(
  //   {
  //     staff: staff,
  //     admin: superadmin,
  //     active: isActive,
  //   },
  //   "checkbox"
  // );
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const postAdmin = () => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    const url2 = `${BASE_URL}api/v1/auth/staff/`;
    // api/v1/auth/staff/
    // const data = {
    //   first_name: firstName,
    //   last_name: lastName,
    //   image: 11,
    //   email: email,
    //   mobile: phoneNumber,
    //   is_active: isActive,
    //   password: passValidation,
    //   is_staff: staff,
    //   is_superuser: superadmin,
    // };
    if (firstName === "") {
      showToast("error", "Type First Name");
    } else if (lastName === "") {
      showToast("error", "Type Last Name");
    } else if (!email.match(mailformat)) {
      showToast("error", "Inter Valid Email Please!");
    } else if (numberValidation?.length !== 10) {
      showToast("error", " Inter Valid Number Please !");
    } else if (passValidation?.length === 0 || passValidation?.length < 8) {
      showToast("error", "Inter Minimum 8 Digit Password !");
    } else {
      const data1 = new FormData();
      data1.append("document", uploaded);
      data1.append("doc_type", 0);
      if (uploaded !== null) {
        axios
          .post(url1, data1)
          .then((res) => {
            console.log(res.data.data);
            if (res.data.status) {
              axios
                .post(url2, {
                  first_name: firstName,
                  last_name: lastName,
                  image: res.data.data.id,
                  email: email,
                  mobile: phoneNumber,
                  is_active: isActive,
                  password: passValidation,
                  is_staff: staff,
                  is_superuser: superadmin,
                })
                .then((res) => {
                  if (res.data.status) {
                    showToast("success", "Data Added Successfully.");

                    setUploaded(null);
                    history.push("admin-and-staff")
                  }
                })
                .catch((err) => {
                  const message = JSON.parse(err.request.response).message;
                  const errorMsg = JSON.parse(err.request.response).errors;
                  for (let value of Object.values(errorMsg)) {
                    showToast("error", value[0]);
                  }

                  showToast("error", message);
                });
            }
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
            }
            console.log(err.request.response);
            showToast("error", message);
          });
      } else {
        axios
          .post(url2, {
            first_name: firstName,
            last_name: lastName,
            image: 11,
            email: email,
            mobile: phoneNumber,
            is_active: isActive,
            password: passValidation,
            is_staff: staff,
            is_superuser: superadmin,
          })
          .then((res) => {
            if (res.data.status) {
              console.log(res.data);
              showToast("success", "Data Added Successfully.");
              setUploaded(null);
              history.push("admin-and-staff")
            }
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
            }

            showToast("error", message);
          });
      }
    }
  };
  return (
    <div className="box-wrapper bg-extra" style={{ minHeight: "100vh" }}>
      <PageHeader CommonData={CommonData} />
      <div className="content_div bg-white row gap-2">
        <div className="col-12 col-md-4 picture">
          <p>Profile Picture</p>
          <div className="select_img">
            <img
              style={{ width: "80%", height: "90%", objectFit: "cover" }}
              src={uploaded ? URL.createObjectURL(uploaded) : select}
              alt=""
            />
            {uploaded && (
              <div className="cross" onClick={removeImg}>
                <X size={20} weight="thin" />
              </div>
            )}
          </div>
          <div
            className="d-flex add-user-btn mt-4 df gap-1"
            style={{ width: "140px" }}
          >
            <img className="icon" src={add} alt="" /> Upload
            <input
              onChange={onSelectFile}
              type="file"
              accept="image/*"
              name=""
              id=""
            />
          </div>
        </div>
        <div className="col-12 col-md-7 mb-4 pb-4">
          <div className="row">
            <div className="col-12 col-md-6 global">
              <p>First Name</p>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                placeholder="First Name"
              />
            </div>
            <div className="col-12 col-md-6 global">
              <p>Last Name</p>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="Last Name"
              />
            </div>

            <div className="col-12 row global mt-3 input_container">
              <p className="col-12">Phone Number</p>
              <div
                className="number col-12"
                style={{ width: "100%", marginLeft: "10px" }}
              >
                <select
                  onChange={(e) => setUserCountryCode(e.target.value)}
                  name=""
                  id=""
                >
                  {countryCode?.map((country) => (
                    <option
                      selected={country.phone_code === "+880"}
                      key={country.id}
                      value={country.phone_code}
                    >
                      {country.phone_code}
                    </option>
                  ))}
                </select>
                <div className="divider"></div>
                <input
                  type="text"
                  onChange={(e) => setNumberValidation(e.target.value)}
                  className="w-100"
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              {isNaN(numberValidation) === true ? (
                <span>
                  <RiErrorWarningLine /> Inter Number Only
                </span>
              ) : numberValidation?.length > 10 ? (
                <span>
                  <RiErrorWarningLine /> Inter 10 Digits Only
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 global mt-3 input_container">
              <p>Email</p>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter a valid email"
              />
              {!email?.match(mailformat) && email?.length > 0 && (
                <span>
                  <RiErrorWarningLine /> invalid Email
                </span>
              )}
            </div>

            <div className="col-12 global mt-3 input_container mt-3">
              <p>Password</p>
              <div
                className="number"
                style={{ position: "relative", width: "100%" }}
              >
                <input
                  type={eye ? "text" : "password"}
                  className="w-100"
                  autoComplete="false"
                  style={{ border: "none", outline: "none" }}
                  onChange={(e) => setPassValidation(e.target.value)}
                  placeholder="Type Password"
                />
                <div onClick={() => changeEye()}>
                  {eye ? (
                    <AiOutlineEye
                      style={{ cursor: "pointer" }}
                      size={24}
                      color="#0000001A"
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ cursor: "pointer" }}
                      size={24}
                      color="#0000001A"
                    />
                  )}
                </div>
              </div>
              {passValidation?.length > 0 && passValidation?.length < 8 && (
                <span>
                  <RiErrorWarningLine /> Minimum 8 characters
                </span>
              )}
            </div>
            {/* <div className="input_container mt-3">
              <span>Password</span>
              <div className="number" style={{ position: "relative" }}>
                <input
                  type={eye ? "text" : "password"}
                  className="w-100"
                  autoComplete="false"
                  onChange={(e) => setPassValidation(e.target.value)}
                />
                <div onClick={() => changeEye()}>
                  {eye ? (
                    <AiOutlineEye
                      style={{ cursor: "pointer" }}
                      size={24}
                      color="#0000001A"
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ cursor: "pointer" }}
                      size={24}
                      color="#0000001A"
                    />
                  )}
                </div>
              </div>
              {passValidation?.length > 0 && passValidation?.length < 8 && (
                <span>
                  <RiErrorWarningLine /> Minimum 8 characters
                </span>
              )}
            </div> */}
            <div className="col-12 global mt-3 d-flex align-items-center">
              <div
                className="d-flex align-items-center"
                style={{ minWidth: "120px" }}
              >
                <p>Is Active</p>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-10px" }}
              >
                <Checkbox
                  onChange={() => setIsActive((prev) => !prev)}
                  label="Outlined"
                  variant="outlined"
                />{" "}
                Yes
              </div>
            </div>
            <div className="col-12 global mt-1 d-flex align-items-center">
              <p style={{ minWidth: "120px" }}>Is Staff</p>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-10px" }}
              >
                <Checkbox
                  onChange={() => setStaff((prev) => !prev)}
                  label="Outlined"
                  variant="outlined"
                />{" "}
                Yes
              </div>
            </div>
            <div className="col-12 global mt-1 d-flex align-items-center">
              <p style={{ minWidth: "120px" }}>Is Super Admin</p>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "-10px" }}
              >
                <Checkbox
                  onChange={() => setSuperadmin((prev) => !prev)}
                  label="Outlined"
                  variant="outlined"
                />{" "}
                Yes
              </div>
            </div>
            <div className="col-12 global2 mt-3">
              <button
                className="back"
                onClick={() => history.push("admin-and-staff")}
              >
                Back
              </button>
              <button className="ms-2  save" onClick={postAdmin}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdminStaff;
