import React from "react"; //Needed for nodejs v.16

import "./App.css";
import { useState } from "react";
// import Login from "./modules/authentication/components/login/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./modules/navbar/component/Header";
import ForgatePassword from "./modules/authentication/components/forgetpass/ForgatePassword";
import ResetPassword from "./modules/authentication/components/resetpass/ResetPassword";

import Login from "./modules/authentication/components/login/Login";




import PackageView from "./modules/package/component/PackageContainer/PackageView";

import Dashboard from "./modules/dashboard/component/Dashboard";

import ViewUser from "./modules/navbar/component/ViewUser";

import AllAdons from "./modules/Addons/AllAdons";
import AddAddons from "./modules/Addons/AddAddons";

import EditAddons from "./modules/Addons/EditAddons";
import Page404 from "./modules/errorPages/Page404";

import Verify from "./modules/authentication/components/Verify/Verify";
import SideNav from "./modules/navbar/component/SideNav";
import ActiveUser from "./modules/ChatUser/ActiveUser/ActiveUser";
import InactiveUser from "./modules/ChatUser/InactiveUser/InactiveUser";
import ApproveUser from "./modules/ChatUser/ApproveUser/ApproveUser";
import Personal from "./modules/Conversation/Personal/Personal";
import Group from "./modules/Conversation/Group/Group";
import GlobalSettings from "./modules/Administration/GlobalSettings/GlobalSettings";
import AdminStaff from "./modules/Administration/AdminStaff/AdminStaff";
import PrivacyPolicy from "./modules/Administration/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./modules/Administration/TermsConditions/TermsAndConditions";
import AboutUs from "./modules/Administration/AboutUs/AboutUs";
import AddAdminStaff from "./modules/Administration/AdminStaff/AddAdminStaff";
import ViewProfile from "./modules/Profile/ViewProfile";
import ChangePass from "./modules/Profile/ChangePass";
import EditAdminStaff from "./modules/Administration/AdminStaff/EditAdminStaff";
import AddGroup from "./modules/Conversation/Group/AddGroup";
import EditGroup from "./modules/Conversation/Group/EditGroup";
import Help from "./modules/Administration/HelpCenter/Help";
import ContactUs from "./modules/Administration/ContactUs/ContactUs";

function App() {
  const [bodyWidth, setBodyWidth] = useState(false);
  const [hideToolbar, setHideToolbar] = useState(false);
  return (
    <div>
      <Router>
        <div
          className={` ${
            !bodyWidth && !hideToolbar
              ? "main-content"
              : !hideToolbar
              ? "main-content-collapsed"
              : ""
          }`}
        >
          {/* {!hideToolbar && <Header setBodyWidth={setBodyWidth} />} */}
          {!hideToolbar && <SideNav  />}
          <div>
            <Switch>
              <Route exact path="/login">
                <Login setHideToolbar={setHideToolbar} />
              </Route>
           
              <Route exact path="/forget_pass">
                <ForgatePassword setHideToolbar={setHideToolbar} />
              </Route>
              <Route exact path="/verify">
                <Verify setHideToolbar={setHideToolbar} />
              </Route>
              <Route exact path="/reset_pass">
                <ResetPassword setHideToolbar={setHideToolbar} />
              </Route>
              
              <Route exact path="/">
                <Dashboard  />
              </Route>
              
              {/* =====customer==== */}
              <Route exact path="/active-user">
                <ActiveUser/>
              </Route>
              <Route exact path="/inactive-user">
                <InactiveUser/>
              </Route>
              <Route exact path="/approve-user">
                <ApproveUser/>
              </Route>
              {/* ==========Coversation======== */}
              <Route exact path="/personal">
                <Personal/>
              </Route>
              <Route exact path="/group">
                <Group/>
              </Route>
              <Route exact path="/add_group">
                <AddGroup/>
              </Route>
              <Route exact path="/edit_group/:id">
                <EditGroup/>
              </Route>
              {/* ==========Coversation======== */}
              {/* ========Administration========== */}
              <Route exact path="/global-settings">
                <GlobalSettings/>
              </Route>
              <Route exact path="/add-admin-and-staff">
                <AddAdminStaff/>
              </Route>
              <Route exact path="/edit-admin-and-staff/:id">
                <EditAdminStaff/>
              </Route>
              <Route exact path="/admin-and-staff">
                <AdminStaff/>
              </Route>
              <Route exact path="/privacy-policy">
                <PrivacyPolicy/>
              </Route>
              <Route exact path="/terms-and-conditions">
                <TermsAndConditions/>
              </Route>
              <Route exact path="/about-us">
                <AboutUs/>
              </Route>
              <Route exact path="/help-center">
                <Help/>
              </Route>
              <Route exact path="/contact-us">
                <ContactUs/>
              </Route>
              {/* ========Administration========== */}
              {/* ========Profile========== */}
              <Route exact path="/view-profile">
                <ViewProfile/>
              </Route>
              <Route exact path="/change-password">
                <ChangePass/>
              </Route>
              {/* ========Profile========== */}
              
              {/* ==========addons======= */}
              <Route exact path="/alladdons">
                <AllAdons />
              </Route>
              <Route exact path="/addaddons">
                <AddAddons />
              </Route>
              <Route exact path="/editaddons/:slug">
                <EditAddons />
              </Route>
              {/* ==========addons======= */}
              <Route exact path="/packageDetails">
                <PackageView />
              </Route>
              {/* =====items===== */}
              
              <Route exact path="/view_user">
                <ViewUser />
              </Route>
              <Route>
                <Page404 setHideToolbar={setHideToolbar} />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
