import React from "react";

const Active = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      style={{transition:'1s all ease'}}
    >
      <rect width="12" height="12" rx="6" fill="#1F5DA0" />
    </svg>
  );
};

export default Active;
